export const addProductMessage = 'Product added successfully';
export const deleteProductMessage = 'Product deleted successfully';
export const updateProductMessage = 'Product updated successfully';
export const addAttributeMessage = 'Attribute added successfully';
export const deleteAttributeMessage = 'Attribute deleted successfully';
export const updateAttributeMessage = 'Attribute updated successfully';
export const addCategoryMessage = 'Category added successfully';
export const deleteCategoryMessage = 'Category deleted successfully';
export const updateCategoryMessage = 'Category updated successfully';
export const addBrandMessage = 'Brand added successfully';
export const deleteBrandMessage = 'Brand deleted successfully';
export const updateBrandMessage = 'Brand updated successfully';