import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Footer from "../header/Footer";
import { REACT_BASE_PATH } from "../../api";
import { useAuth } from "./AuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
// import('../../components/WebPanel/WebPanelRoutes.css');

const VendorLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const submitData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${REACT_BASE_PATH}/auth/vendor/login`,
        {
          vendor: {
            vendor_contact: email,
            password: password,
          },
        }
      );

      // Store vendor data in localStorage
      console.log(response?.data, "--------------------");
      localStorage.setItem("vendor", JSON.stringify(response?.data));
      localStorage.setItem("user", JSON.stringify(response?.data));

      // Call the login function from AuthContext
      login(response.data.vendor);

      // Navigate to the dashboard
      navigate("/admin");
      window.location.reload()

      console.log(response.data, "Login Successfully");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
      } else {
        console.log("An error occurred during login:", error.message);
      }
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row mb-5">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Signin</h5>
                  <form onSubmit={submitData}>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="email-input"
                      placeholder=""
                      required
                    />
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="email-input"
                      placeholder=""
                      required
                    />
                    <br />
                    <br />
                    <div className="sbtn-box">
                      <button type="submit" className="sub-btn">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <NavLink to="/vendorsignup">
                <button className="create-acc">
                  If You have not Signup. Plz Signup Before
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default VendorLogin;
