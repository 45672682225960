import React, { useContext, useEffect, useState } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import defaultImage from "../../img/bagpack.png";
import { REACT_BASE_PATH } from "../../api";
import Cookies from 'js-cookie'
import { CartContext } from "./cart/CartContext";

const Whishlist = () => {

  const [wishlistItems, setWishlistItems] = useState([]);
  const { state, dispatch } = useContext(CartContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWishlistItems = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');


      if (!userId || !accessToken) {
        alert("You need to be logged in to view your wishlist.");
        navigate("/signin");
        return;
      }

      try {
        const response = await axios.get(
          `${REACT_BASE_PATH}/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          console.log(response.data.data)
          setWishlistItems(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlistItems();
  }, [navigate]);

  // console.log(cartItems)

  const handleDeleteFromWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    // const accessToken = localStorage.getItem("accessToken");
    const accessToken = Cookies.get('krizaar-user');


    if (!accessToken || !userId) {
      alert("You need to be logged in to delete products from your wishlist.");
      navigate("/signin");
      return;
    }

    try {
      const response = await axios.post(
        `https://krizaar.com/api/product/wishlist/delete`,
        {
          userId: userId,
          productIds: [productId],
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        alert("Product removed from wishlist successfully!");
        setWishlistItems((prevItems) =>
          prevItems.filter((item) => item._id !== productId)
        );
      } else {
        alert("Failed to remove product from wishlist.");
      }
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddToCart = async (product) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
      // productMaxStockQuantity: productStockQuantity,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      alert("Product is already in the cart!");
      return;
    }

    try {
      const response = await axios.post(
        `https://krizaar.com/api/cart/user/add/product/cart`,
        {
          id: userId,
          product_id: product._id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch({ type: 'ADD_TO_CART', payload: productInfo });
        alert("Product added to the cart!");
      } else {
        alert("Failed to add product to the cart");
      }
    } catch (error) {
      console.error("Error adding product to the cart:", error);
      alert("An error occurred. Please try again.");
    }
  }

  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row mt-3">
            <p>Account {">"} Wishlist</p>
          </div>
          <div className="row my-3">
            <div className="col-lg-6">
              <h5>My Wishlist</h5>
            </div>
            <div className="col-lg-6">
              <div className="filter-box">
                <div className="search-container">
                  <input type="search" placeholder="Search this list" />
                </div>
                <div>
                  <li className="nav-item dropdown">
                    <NavLink
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Filter & sort
                    </NavLink>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <NavLink className="dropdown-item" to="#">
                          Action
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="#">
                          Another action
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="dropdown-item" to="#">
                          Something else here
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <hr className="hor-line" />
          {wishlistItems.length > 0 ? (
            wishlistItems.map((item, index) => (
              <React.Fragment key={item._id}>
                <div className="row my-3">
                  <div className="col-lg-4">
                    <div className="order-img">
                      <img
                        src={
                          item.product_banner_image
                            ? (item.isProductFromAliExpress ? item.product_banner_image : `https://krizaar.com/api/uploads/${item.product_banner_image}`)
                            : defaultImage
                        }
                        alt={item.product_name}
                        className="img-fluid product-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 whish-sec">
                    <div className="o-details">
                      <h6>Product Name: {item.product_name}</h6>
                      <p>Rating: {item.rating || "No rating available"}</p>
                      <p>Price: ₹{item.price || "Price not available"}</p>
                    </div>
                    <div className="o-btns">
                      <div>
                        <button className="order-btn1" onClick={() => navigate(`/singleproduct/${item._id}`)}>Go to Product</button>
                        <br />
                        <button className="order-btn1">Share</button>
                        <br />
                        <button
                          className="order-btn1"
                          onClick={() => handleDeleteFromWishlist(item._id)}
                        >
                          Delete
                        </button>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
                {index < wishlistItems.length - 1 && (
                  <hr className="whor-line" />
                )}
              </React.Fragment>
            ))
          ) : (
            <p>No items in your wishlist.</p>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Whishlist;
