import React, { useContext, useEffect, useMemo } from 'react';
import Header from '../../../componenth/header/Header';
import Footer from '../../../componenth/header/Footer';
import { CartContext } from './CartContext';
import Cookies from 'js-cookie';
import axios from 'axios';
import { CheckoutContext } from '../checkout/CheckoutContext';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const { state: cartState, dispatch: cartDispatch } = useContext(CartContext);
    const { state: checkoutState, dispatch: checkoutDispatch } = useContext(CheckoutContext);

    const navigate = useNavigate();


    const handleIncrease = (id) => {
        cartDispatch({ type: 'INCREASE_QUANTITY', payload: { id } });
    };

    const handleDecrease = (id) => {
        cartDispatch({ type: 'DECREASE_QUANTITY', payload: { id } });
    };

    const removeFromCart = async (productId) => {
        const userId = localStorage.getItem("userId");
        const accessToken = Cookies.get('krizaar-user');

        if (!accessToken || !userId) {
            cartDispatch({ type: 'REMOVE_FROM_CART', payload: { id: productId } });
            alert("Product removed from the cart!");
        } else {
            try {
                const response = await axios.post(
                    `https://krizaar.com/api/cart/user/remove/product/cart`,
                    {
                        id: userId,
                        product_id: productId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                if (response.status === 200) {
                    cartDispatch({ type: 'REMOVE_FROM_CART', payload: { id: productId } });
                    alert("Product removed from the cart!");
                } else {
                    alert("Failed to add product to the cart");
                }
            } catch (error) {
                console.error("Error adding product to the cart:", error);
                alert("An error occurred. Please try again.");
            }
        }
    }

    // Calculate totals and tax
    const TAX_RATE = 0.18;
    const totalPriceBeforeTax = useMemo(() => cartState.totalPrice - cartState.totalDiscount, [cartState.totalPrice, cartState.totalDiscount]);
    const taxAmount = useMemo(() => totalPriceBeforeTax * TAX_RATE, [totalPriceBeforeTax]);
    const orderTotal = useMemo(() => totalPriceBeforeTax + taxAmount, [totalPriceBeforeTax, taxAmount]);

    const handleCheckout = async () => {
        const userId = localStorage.getItem("userId");
        const accessToken = Cookies.get('krizaar-user');

        if (!accessToken || !userId) {
            alert("You need to be logged in to proceed with the purchase.");
            navigate("/signin");
            return;
        }

        checkoutDispatch({ type: 'CLEAR_CHECKOUT_ITEMS' });
        checkoutDispatch({ type: 'ADD_TO_CHECKOUT', payload: cartState.cart });

        navigate("/checkout");
    };


    return (
        <>
            <Header />
            <div className='container'>
                <div className="cart">
                    <div className="cart-header">
                        <span>Home &gt; Cart</span>
                        <input type="text" placeholder="Search Orders" className="cart-search" />
                    </div>
                    <div className='cart-body row m-0'>
                        <div className="cart-items col-lg-8 col-md-8 col-sm-6 col-12">
                            {
                                cartState.cart.length > 0 ? cartState.cart.map((product) => (
                                    <CartItem
                                        key={product.id}
                                        product={product}
                                        onRemove={removeFromCart}
                                        onIncrease={handleIncrease}
                                        onDecrease={handleDecrease}
                                    />
                                )) : <h2>No items in your cart</h2>
                            }
                        </div>
                        <div className="cart-summary col-lg-4 col-md-4 col-sm-6 col-12">
                            <h3>PRICE DETAILS</h3>
                            <div className="summary-detail">
                                <span>Price ({cartState.totalQuantity} items) :</span>
                                <span>$ {cartState.totalPrice}</span>
                            </div>
                            <div className="summary-detail">
                                <span>Shipping & Handling:</span>
                                <span className="free">Free</span>
                            </div>
                            <div className="summary-detail">
                                <span>Promotion Applied: </span>
                                <span className="discount">-$ {cartState.totalDiscount}</span>
                            </div>
                            <hr />
                            <div className="summary-detail">
                                <span>Cart Total: </span>
                                <span>$ {totalPriceBeforeTax.toFixed(2)}</span>
                            </div>
                            <div className="summary-detail savings">
                                <span>You will save $ {cartState.totalDiscount} on this order</span>
                            </div>
                            <div className="checkout-button">
                                <button className="btn" onClick={handleCheckout}>Proceed to Checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

const CartItem = ({ product, onRemove, onIncrease, onDecrease }) => {
    const navigate = useNavigate()
    return (
        <div className="cart-item">
            <img onClick={() => navigate(`/singleproduct/${product.id}`)} src={product.isProductFromAliExpress ? product.product_banner_image : `https://krizaar.com/api/uploads/${product.product_banner_image}`} alt={product.name} className="product-image" />
            <div className="cart-product-details">
                <h4 onClick={() => navigate(`/singleproduct/${product.id}`)}>{product.name}</h4>
                {product.seller && product.seller.first_name && (
                    <span>
                        Seller: {product.seller.first_name || ''} {product.seller.last_name || ''}
                    </span>
                )}
                {product.offer_price ?
                    <div className="product-price">
                        <span className="cart-current-price">MRP:</span>
                        <span className="cart-mrp">$ {product.price}</span>
                        <span className="cart-current-price">$ {product.offer_price}</span>
                        <span className="offers-applied">2 offers applied</span>
                    </div> :
                    <div className="product-price">
                        <span className="cart-current-price">MRP: ${product.price}</span>
                        <span className="offers-applied">2 offers applied</span>
                    </div>
                }
                <div className="delivery-info d-flex gap-3">
                    Delivery by Thu Jun 27 | <span className='text-decoration-line-through'>$ 40</span> <span className="free">Free</span>
                </div>
                <div className="product-actions">
                    <div className="quantity-control">
                        <button className="quantity-btn" onClick={() => onDecrease(product.id)}>-</button>
                        <span className="quantity m-0">{product.quantity}</span>
                        <button className="quantity-btn" onClick={() => onIncrease(product.id)}>+</button>
                    </div>
                    <button className="save-later">Save to later</button>
                    <button className="remove" onClick={() => onRemove(product.id)}>Remove</button>
                </div>
            </div>
        </div>
    );
};

export default Cart;
