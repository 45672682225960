import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_PATH } from "../../../../api";
import axios from "axios";
import { formatDateTime } from "../../helper/DateTimeFormat";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AllOrders = () => {
    const navigate = useNavigate()
    const [selectedOption, setSelectedOption] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [totalItems, setTotalItems] = useState(0);
    const totalPages = Math.ceil(totalItems / selectedOption);
    const [dropIndex, setdropndex] = useState(0);
    const [dropShow, setdropShow] = useState(false)
    const open = Boolean(dropShow);
    const [dropdownList, setdropdownList] = useState(['confirmed', 'dispatched', 'out for delivery', 'delivered', 'canceled'])

    

    const fetchOrderList = useCallback(async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/listOrders`, {
                "vendorId": "0",
                "page": currentPage,
                "pagesize": selectedOption

            });
            if (response.data.status === 200) {
                setdata(response.data.data.reverse());
                setLoading(false)
                setTotalItems(response.data.totalItems)
            } else {
                setdata([])
            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }
    }, [currentPage, selectedOption]);

    const handleChange = (event) => {
        const value = parseInt(event.target.value);
        setSelectedOption(value);
        setCurrentPage(1);
    };

    useEffect(() => {
        fetchOrderList()
    }, [fetchOrderList])

    const navigateToOrderDetail = (data) => {
        navigate('/admin/OrderDetails/', { state: data })
    }

    const _handleStatus = async (id, status) => {
        try {
            const response = await axios.post(`${REACT_BASE_PATH}/product/updateOrderStatus`, {
                "orderId": id,
                "status": status
            });
            if (response.data.status === 200) {
                setdropShow(!dropShow)
                fetchOrderList()
            } else {
                console.log('error');

            }
        } catch (error) {
            console.error("Error fetching category:", error);
        }

    }

    const dropdownClick = (item, index) => {
        setdropShow(false)
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };


    return (
        <>
            <section id="admin-main-content" >
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">All Orders</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card-header d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-start table-header-page">
                            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0">
                                <div id="DataTables_Table_0_filter" className="dataTables_filter">
                                    <label>
                                        <input
                                            type="search"
                                            className="form-control"
                                            placeholder="Search Orders"
                                            aria-controls="DataTables_Table_0"
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex justify-content-start justify-content-md-end align-items-baseline">
                                <div className="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                                    <div className="dataTables_length mx-n2" id="DataTables_Table_0_length">
                                        <label>
                                            <select
                                                name="DataTables_Table_0_length"
                                                aria-controls="DataTables_Table_0"
                                                className="form-select"
                                                id="entries"
                                                value={selectedOption}
                                                onChange={handleChange}
                                            >
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="70">70</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="mb-6">
                                        <button className="btn btn-theam mx-2" type="button">
                                            <span>Export CSV</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <div className="mlr-10 mb-4">
                                <table className="tableGap">
                                    <thead>
                                        <tr>
                                            <th>Order ID</th>
                                            <th>User Name</th>
                                            <th>Order Date</th>
                                            <th>Payment Status</th>
                                            <th>Total Price</th>
                                            <th>Order Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length > 0 && data.map((items, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {items?._id}
                                                    </td>
                                                    <td>{items?.userId?.first_name} {items?.userId?.last_name}</td>
                                                    <td>{formatDateTime(items.orderDate)}</td>
                                                    <td>{items.paymentStatus}</td>
                                                    <td>
                                                        {items.totalPrice}
                                                    </td>
                                                    <td style={{ textTransform: 'capitalize' }}>
                                                        {items.status}
                                                    </td>
                                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                                        <button className="edit-btn" onClick={() => navigateToOrderDetail(items._id)}>
                                                            <i className="fa fa-eye"></i>
                                                        </button>
                                                        {items.status == 'delivered' ?
                                                            <button className="trash-icon disabledBtn">
                                                                <MoreVertIcon />
                                                            </button> :
                                                            <div class="btn-group dropend">
                                                                <button className="trash-icon" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <MoreVertIcon />
                                                                </button>
                                                                <ul class="dropdown-menu">
                                                                    {dropdownList.map((option) => (
                                                                        <li className="text-capitalize" key={option} onClick={() => _handleStatus(items._id, option)}>
                                                                            <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>}
                                                    </td>
                                                </tr>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-12 col-md-6 mt-2">
                            <div className="dataTables_info">
                                Displaying {currentPage} to {Math.min(currentPage * selectedOption, totalItems)} of {totalItems} entries
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="dataTables_paginate">
                                <ul className="pagination">
                                    <li
                                        className={`paginate_button page-item previous ${currentPage === 1 ? "disabled" : ""}`}
                                    >
                                        <span
                                            className="page-link"
                                        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                        >
                                            <i className="fa fa-chevron-left bx-18px"></i>
                                        </span>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li
                                            key={index}
                                            className={`paginate_button page-item ${currentPage === index + 1 ? "active" : ""}`}
                                        >
                                            <span
                                                className="page-link"
                                            onClick={() => handlePageChange(index + 1)}
                                            >
                                                {index + 1}
                                            </span>
                                        </li>
                                    ))}
                                    <li
                                        className={`paginate_button page-item next ${currentPage === totalPages ? "disabled" : ""}`}
                                    >
                                        <span
                                            className="page-link"
                                        onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                        >
                                            <i className="fa fa-chevron-right bx-18px"></i>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllOrders;