import React from "react";

const Sidebar = ({ selectedCategory, setSelectedCategory }) => {
  const categories = [
    { id: "delivery-related", label: "Delivery Related" },
    { id: "login-account", label: "Login & my Account" },
    { id: "refund-related", label: "Refund Related" },
    { id: "return-pickup", label: "Return & Pickup" },
    { id: "cancellation-related", label: "Cancellation Related" },
    { id: "payment", label: "Payment" },
  ];

  return (
    <div className="faq-sidebar">
      <h2>Faq</h2>
      <ul>
        {categories.map((category) => (
          <li
            key={category.id}
            className={selectedCategory === category.id ? "active" : ""}
            onClick={() => setSelectedCategory(category.id)}
          >
            {category.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
