import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";

const Header = ({ user = { name: "Guest" } }) => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowCategories, setOverflowCategories] = useState([]);

  const handleMouseEnter = (index) => {
    setDropdownOpen(index);
    const rootElement = document.getElementById("root"); // Get the element with id 'root'
    if (rootElement) {
      rootElement.classList.add("blur-background"); // Add blur class to root element
    }
  };
  
  const handleMouseLeave = () => {
    setDropdownOpen(null);
    const rootElement = document.getElementById("root"); // Get the element with id 'root'
    if (rootElement) {
      rootElement.classList.remove("blur-background"); // Remove blur class from root element
    }
  };

  useEffect(() => {
    const fetchAllCategories = async () => {
      try {
        const response = await axios.get(
          `https://krizaar.com/api/product/category-subcategory`
        );
        if (response.data.success) {
          const maxVisible = 7; // Set the max number of categories visible before 'More'
          const fetchedCategories = response.data.data;
          setVisibleCategories(fetchedCategories.slice(0, maxVisible));
          setOverflowCategories(fetchedCategories.slice(maxVisible));
        } else {
          console.error("Error fetching categories:", response.data.message);
          alert("An error occurred while fetching categories. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        alert("An error occurred. Please try again.");
      }
    };

    fetchAllCategories();
  }, []);

  const renderCategoryDropdown = (category, index) => (
    <li
      className={`nav-item header-category-item ${
        dropdownOpen === index ? "active-background" : ""
      }`} 
      key={category._id}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={`/category/${category._id}`} className="nav-link header-category-link">
        {category.category_name}
      </Link>

      {dropdownOpen === index && category.children.length > 0 && (
        <div className="header-dropdown-menu header-dropdown-full-width">
          <div className="category-information">
            <h2 className="category-title">{category.category_name}</h2>
            <img
              src={`https://krizaar.com/api/uploads/${category.category_image}`}
              className="img-fluid"
              alt="category-image"
            />
          </div>
          <div className="subcategories-dropdown">
            <div className="header-dropdown-row">
              {category.children.slice(0, 4).map((subcategory, colIndex) => (
                <div className="header-dropdown-col" key={colIndex}>
                  <NavLink to={`/subcategory/${subcategory._id}`}>
                    <h6 className="header-dropdown-column-header text-dark">{subcategory.category_name}</h6>
                  </NavLink>
                  <ul className="header-dropdown-list">
                    {subcategory.children.map((childcategory) => (
                      <li key={childcategory._id}>
                        <NavLink
                          to={`/childcategory/${childcategory._id}`}
                          className="header-dropdown-item"
                        >
                          {childcategory.category_name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            {category.children.length > 4 ? (
              <NavLink to={`/category/${category._id}`}>Load More</NavLink>
            ) : null}
          </div>
        </div>
      )}
    </li>
  );

  return (
    <nav className="header navbar navbar-expand-lg navbar-light p-0">
      <div className="container-fluid navbar-background">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {visibleCategories.map((category, index) =>
              renderCategoryDropdown(category, index)
            )}

            {overflowCategories.length > 0 && (
              <li
                className={`nav-item header-category-item dropdown ${
                  dropdownOpen === "more" ? "active-background" : ""
                }`}
                onMouseEnter={() => handleMouseEnter("more")}
                onMouseLeave={handleMouseLeave}
              >
                <span className="nav-link header-category-link dropdown-toggle" role="button">
                  More
                </span>

                {dropdownOpen === "more" && (
                  <ul className="more-dropdown-menu">
                    {overflowCategories.map((category) => (
                      <li key={category._id}>
                        <NavLink to={`/category/${category._id}`} className="dropdown-item">
                          {category.category_name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
