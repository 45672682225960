import React from "react";
import "./faq.css";

const faqData = {
  "delivery-related": {
    question:
      "What should I do if my order is approved but hasn't been shipped yet?",
    answer:
      "Sellers usually ship orders 1-2 business days before the delivery date so that they reach you on time. In case your order hasn't been shipped within this time please contact our Customer Support so that we can look into it.",
  },
  "login-account": {
    question: "How do I login to my account?",
    answer:
      "You can login to your account using your email and password. If you've forgotten your password, use the 'Forgot Password' link to reset it.",
  },
  "refund-related": {
    question: "How do I request a refund?",
    answer:
      "To request a refund, go to your order history, select the order you want to refund, and follow the instructions.",
  },
  "return-pickup": {
    question: "How do I return a product?",
    answer:
      "To return a product, go to your order history, select the order you want to return, and follow the instructions for initiating a return.",
  },
  "cancellation-related": {
    question: "How do I cancel my order?",
    answer:
      "To cancel your order, go to your order history, select the order you want to cancel, and follow the instructions.",
  },
  payment: {
    question: "What payment methods are accepted?",
    answer:
      "We accept various payment methods including credit cards, debit cards, and PayPal.",
  },
};

const FAQContent = ({ selectedCategory }) => {
  const content = faqData[selectedCategory];

  return (
    <div className="faq-content">
      <h2>{content?.question}</h2>
      <p>{content?.answer}</p>
      <div className="feedback">
        <p>Was this helpful?</p>
        <button>Yes</button>
        <button>No</button>
      </div>
    </div>
  );
};

export default FAQContent;
