import React from 'react';

const HeroSection = ({heroSectionItems}) => {
  return (
    <section className="hero-section ">
      <div className='container'>
      <div className='hero-section-items'>
        <div className="hero-text">
          <h5>{heroSectionItems.title}</h5>
          <h1>{heroSectionItems.subtitle}</h1>
          <p>{heroSectionItems.content}</p>
          <span>{heroSectionItems.content2}</span>
          {/* <button className="btn btn-dark start-free-trial-button py-3 font-extrabold">Log in to set up Shopify Payments</button><br/> */}
          {/* <a href="#">Don't have a Shopify store? Start free, then get your first month for ₹20.</a> */}
        </div>
        <div className="hero-image">
          <img src={heroSectionItems.image} className='img-fluid' alt="hero-image" />
        </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
