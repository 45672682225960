import React, { useEffect } from "react";
import Header2 from "./Header2";
import Header1 from "./Header1";
import Header3 from "./Header3";

const Header = () => {
  useEffect(() => {
    import('../../components/WebPanel/WebPanelRoutes.css')
  }, []);
  return (
    <>
      <Header1 />
      <Header2 />
      <Header3 />
    </>
  );
};

export default Header;
