import React from 'react'
import Header from '../../../componenth/header/Header'
import Footer from '../../../componenth/header/Footer'
import './category.css'
import Hero from '../../../componenth/common/Hero'
import ChildCategoryProducts from './ChildCategoryProducts'


const ChildCategory = () => {
  return (
    <>
      <Header/>
      <Hero/>
      <ChildCategoryProducts/>
      <Footer/>
    </>
  )
}

export default ChildCategory
