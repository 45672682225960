import axios from 'axios';
import React, { useState } from 'react';
import Cookies from 'js-cookie'

const AddNewReviewModal = ({ isOpen, onClose, productId }) => {
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [images, setImages] = useState([]);
    const [hoverRating, setHoverRating] = useState(0);

    const handleImageChange = (e) => {
        setImages(Array.from(e.target.files));
    };

    const handleSubmit = async () => {
        const userId = localStorage.getItem("userId");
        const accessToken = Cookies.get('krizaar-user');
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('productId', productId);
        formData.append('rating', rating);
        formData.append('comment', reviewText);
        images.forEach((image, index) => {
            formData.append(`image_${index}`, image);
        });

        try {
            const response = await axios.post(
                `https://krizaar.com/api/product/userproductreview`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response){
                onclose()
            }
        } catch (e) {
            console.error("Error fetching product reviews:", e);
        }
    };

    return (
        isOpen && (
            <div className="review-modal">
                <div className="review-modal-content">
                    <span className="review-close" onClick={onClose}>&times;</span>
                    <h2>Add New Review</h2>
                    <div className="form-group">
                        <label>Rating:</label>
                        <div className="rating-stars">
                            {[...Array(5)].map((_, i) => (
                                <span
                                    key={i + 1}
                                    className={`star ${i < (hoverRating || rating) ? 'filled' : ''}`}
                                    onClick={() => setRating(i + 1)}
                                    onMouseEnter={() => setHoverRating(i + 1)}
                                    onMouseLeave={() => setHoverRating(0)}
                                >
                                    &#9733;
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Review Text:</label>
                        <textarea value={reviewText} onChange={(e) => setReviewText(e.target.value)} className="review-textarea"></textarea>
                    </div>
                    <div className="form-group">
                        <label>Images:</label>
                        <input type="file" multiple onChange={handleImageChange} className="image-input" />
                    </div>
                    <div className="image-preview">
                        {images.length > 0 && (
                            <div className="image-preview-container">
                                {images.map((image, index) => (
                                    <img key={index} src={URL.createObjectURL(image)} alt={`preview-${index}`} className="preview-image" />
                                ))}
                            </div>
                        )}
                    </div>
                    <div className=' text-center mt-4'>
                        <button onClick={handleSubmit} className="submit-button">Submit</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddNewReviewModal;
