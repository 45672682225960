import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const ReturnDetails = () => {
    return (
        <>
            <div>
                <section className="custom-cart-panel mb-3">
                    <div className="container-fuild">
                        <div className="row">
                            <div className="vendor-top col-12">
                                <h5 className="title-v">Orders ID : #13245679</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="row">
                    <div className="col-md-8">
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Orders Details</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="mlr-10 table-responsive mb-4">
                                <table className="tableGap">
                                    <thead>
                                        <tr>
                                            <th>Sr.</th>
                                            <th>Products</th>
                                            <th>Color</th>
                                            <th>Price</th>
                                            <th>QTY</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>iPhone 13 Pro</td>
                                            <td>Red</td>
                                            <td>Rs. 54000</td>
                                            <td>
                                                1
                                            </td>
                                            <td>54000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>iPhone 13 Pro</td>
                                            <td>Red</td>
                                            <td>Rs. 54000</td>
                                            <td>
                                                1
                                            </td>
                                            <td>54000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>iPhone 13 Pro</td>
                                            <td>Red</td>
                                            <td>Rs. 54000</td>
                                            <td>
                                                1
                                            </td>
                                            <td>54000</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>iPhone 13 Pro</td>
                                            <td>Red</td>
                                            <td>Rs. 54000</td>
                                            <td>
                                                1
                                            </td>
                                            <td>54000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="orderPrice">
                                <p>Sub total  : Rs. 56431</p>
                                <p>Discount  : Rs. 120</p>
                                <p>TAX  : Rs. 244</p>
                                <p>Total  : Rs. 57451</p>
                            </div>
                        </section>
                        <section className="custom-cart-panel">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Shipping Activity</h5>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="timeline pb-0 mb-0">
                                    <li class="timeline-item timeline-item-transparent border-primary">
                                        <span class="timeline-point timeline-point-primary"></span>
                                        <div class="timeline-event">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Order was placed (Order ID: #32543)</h6>
                                                <small class="text-muted">Tuesday 11:29 AM</small>
                                            </div>
                                            <p class="mt-3">Your order has been placed successfully</p>
                                        </div>
                                    </li>
                                    <li class="timeline-item timeline-item-transparent border-primary">
                                        <span class="timeline-point timeline-point-primary"></span>
                                        <div class="timeline-event">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Pick-up</h6>
                                                <small class="text-muted">Wednesday 11:29 AM</small>
                                            </div>
                                            <p class="mt-3 mb-3">Pick-up scheduled with courier</p>
                                        </div>
                                    </li>
                                    <li class="timeline-item timeline-item-transparent border-primary">
                                        <span class="timeline-point timeline-point-primary"></span>
                                        <div class="timeline-event">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Dispatched</h6>
                                                <small class="text-muted">Thursday 11:29 AM</small>
                                            </div>
                                            <p class="mt-3 mb-3">Item has been picked up by courier</p>
                                        </div>
                                    </li>
                                    <li class="timeline-item timeline-item-transparent border-primary">
                                        <span class="timeline-point timeline-point-primary"></span>
                                        <div class="timeline-event">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Package arrived</h6>
                                                <small class="text-muted">Saturday 15:20 AM</small>
                                            </div>
                                            <p class="mt-3 mb-3">Package arrived at an Amazon facility, NY</p>
                                        </div>
                                    </li>
                                    <li class="timeline-item timeline-item-transparent border-left-dashed">
                                        <span class="timeline-point timeline-point-primary"></span>
                                        <div class="timeline-event">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Dispatched for delivery</h6>
                                                <small class="text-muted">Today 14:12 PM</small>
                                            </div>
                                            <p class="mt-3 mb-3">Package has left an Amazon facility, NY</p>
                                        </div>
                                    </li>
                                    <li class="timeline-item timeline-item-transparent border-transparent pb-0">
                                        <span class="timeline-point timeline-point-secondary"></span>
                                        <div class="timeline-event pb-0">
                                            <div class="timeline-header">
                                                <h6 class="mb-0">Delivery</h6>
                                            </div>
                                            <p class="mt-1 mb-0">Package will be delivered by tomorrow</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </div>
                    <div className="col-md-4">
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Shipping Address</h5>
                                    </div>
                                </div>
                                <div className="addressBilling mb-2">
                                    <p>In front of Mandi Gate,</p>
                                    <p>Behind Vishnu temple</p>
                                    <p>SamrathPur, Auraiya, 206122</p>
                                    <p>Auraiya, U.P., India</p>
                                </div>
                            </div>
                        </section>
                        <section className="custom-cart-panel mb-3">
                            <div className="container-fuild">
                                <div className="row">
                                    <div className="vendor-top col-12">
                                        <h5 className="title-v">Billing Address</h5>
                                    </div>
                                </div>
                                <div className="addressBilling mb-2">
                                    <p>In front of Mandi Gate,</p>
                                    <p>Behind Vishnu temple</p>
                                    <p>SamrathPur, Auraiya, 206122</p>
                                    <p>Auraiya, U.P., India</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReturnDetails;