import React, { useState } from 'react'
import profileIcon from '../img/woman.png'

const ProfileAdmin = () => {
    return (
        <>
            <section className="custom-cart-panel mb-3">
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">My Profile</h5>
                        </div>
                    </div>
                </div>
                <div className="card-header border-top px-0 pt-4 py-0">
                    <div className="vendorProfile">
                        <div className=''>
                            <img src={profileIcon} />
                        </div>
                        <div className='flex-grow-1'>
                            <h5 className='mb-3'>Ranjeet Singh <span className='badge'>Active</span> </h5>
                            <h5><span className='spanSpace'>ID:-</span>#vendorId1234</h5>
                            <h6><span className='spanSpace'>Email ID:- </span>Ranjeet.Singh@gmail.com</h6>
                            <h6><span className='spanSpace'>Mobile No:-</span>+91-9876543210</h6>
                        </div>
                    </div>
                </div>
            </section>
            </>
    )
}

export default ProfileAdmin;