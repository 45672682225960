import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const ReturnRefund = () => {
  useEffect(()=>{
    //import('./WebPanelRoutes.css');
  },[])
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="my-5">
              <p>
                At Krizaar, customer satisfaction is our top priority. If you
                are not completely satisfied with your purchase, we are here to
                help.
              </p>
              <h4>Returns:</h4>
              <p>
                You have 30 calendar days to return an item from the date you
                received it. To be eligible for a return, your item must be
                unused and in the same condition that you received it. The item
                must also be in the original packaging. Please include the
                receipt or proof of purchase with your return.
              </p>
              <h4>Refunds:</h4>
              <p>
                Once we receive your item, we will inspect it and notify you
                that we have received your returned item. We will immediately
                inform you of the status of your refund after inspecting the
                item. If your return is approved, we will initiate a refund to
                your original method of payment. You will receive the credit
                within a certain amount of days, depending on your card issuer's
                policies.
              </p>
              <h4>Shipping:</h4>
              <p>
                You will be responsible for paying your own shipping costs for
                returning your item. Shipping costs are non-refundable. If you
                receive a refund, the cost of return shipping will be deducted
                from your refund.
              </p>
              <h4>Exchanges:</h4>
              <p>
                If you received a defective or damaged item, we would be happy
                to exchange it for the same item. Please contact us at
                <NavLink to="mailto:info@krizaar.com">info@krizaar.com</NavLink>
                to arrange an exchange.
              </p>
              <h4>Contact Us:</h4>
              <p>
                If you have any questions about our Return & Refund Policy,
                please contact us at
                <NavLink to="mailto:info@krizaar.com">info@krizaar.com</NavLink>
              </p>
              <p>
                Thank you for shopping with Krizaar. We value your business and
                are committed to providing you with the best customer service.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ReturnRefund;
