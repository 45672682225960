import React, { createContext, useReducer, useEffect } from 'react';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('cartState');
        return serializedState ? JSON.parse(serializedState) : undefined;
    } catch (e) {
        console.error("Could not load state", e);
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('cartState', serializedState);
    } catch (e) {
        console.error("Could not save state", e);
    }
};

const initialState = loadState() || {
    cart: [],
    totalPrice: 0,
    totalQuantity: 0,
    totalDiscount: 0,
};

const CartContext = createContext();

const calculateTotals = (cart) => {
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalQuantity = 0;

    cart.forEach(item => {
        if (item.offer_price) {
            totalPrice += item.offer_price * item.quantity;
            totalDiscount += (item.price - item.offer_price) * item.quantity;
            totalQuantity += item.quantity;
        } else {
            totalPrice += item.price * item.quantity;
            totalQuantity += item.quantity;
        }
    });

    return { totalPrice, totalDiscount, totalQuantity };
};

const cartReducer = (state, action) => {
    let newCart;

    switch (action.type) {
        case 'ADD_TO_CART':
            newCart = [...state.cart, action.payload];
            break;
        case 'REMOVE_FROM_CART':
            newCart = state.cart.filter(item => item.id !== action.payload.id);
            break;
        case 'INCREASE_QUANTITY':
            newCart = state.cart.map(item =>
                item.id === action.payload.id && item.quantity < item.productMaxStockQuantity
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            break;
        case 'DECREASE_QUANTITY':
            newCart = state.cart.map(item =>
                item.id === action.payload.id && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            );
            break;
        default:
            return state;
    }

    const { totalPrice, totalDiscount, totalQuantity } = calculateTotals(newCart);

    const newState = {
        ...state,
        cart: newCart,
        totalPrice,
        totalDiscount,
        totalQuantity,
    };

    saveState(newState);
    return newState;
};

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        saveState(state);
    }, [state]);

    return (
        <CartContext.Provider value={{ state, dispatch }}>
            {children}
        </CartContext.Provider>
    );
};

export { CartContext, CartProvider };
