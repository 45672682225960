import React from 'react';
import productImage from '../../img/firstlbg.jpg'

const VendorProducts = () => {
    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Product List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>SKU</th>
                            <th>Price</th>
                            <th>Brand Name</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <img
                                    src={productImage}
                                    alt="product"
                                    width="50"
                                    height="50"
                                />
                            </td>
                            <td>Product Name</td>
                            <td>#squ_ID_Product</td>
                            <td>
                                2599
                            </td>
                            <td>Brand Name</td>
                            <td>Category Name</td>
                            
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={productImage}
                                    alt="product"
                                    width="50"
                                    height="50"
                                />
                            </td>
                            <td>Product Name</td>
                            <td>#squ_ID_Product</td>
                            <td>
                                2599
                            </td>
                            <td>Brand Name</td>
                            <td>Category Name</td>
                            
                        </tr>
                        <tr>
                            <td>
                                <img
                                    src={productImage}
                                    alt="product"
                                    width="50"
                                    height="50"
                                />
                            </td>
                            <td>Product Name</td>
                            <td>#squ_ID_Product</td>
                            <td>
                                2599
                            </td>
                            <td>Brand Name</td>
                            <td>Category Name</td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default VendorProducts;