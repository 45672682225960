import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../components/WebPanel/cart/CartContext";
import Cookies from 'js-cookie';
import CountdownTimer from "./CountdownTimer";

const Slider = () => {
  const [beveragesProducts, setBeveragesProducts] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);

  useEffect(() => {
    const fetchBeveragesProducts = async () => {
      try {
        const response = await axios.post('https://krizaar.com/api/product/category/product', {
          category_id: '66ae20afef7af1d34d8c0491',
          level: 0,
          page: 1,
          pageSize: 10
        });
        const subcategories = [...new Set(response.data.data.map((item) => item.category_details_level1.category_name))];
        setBeveragesProducts(response.data.data);
        setAllSubCategories(subcategories);
      } catch (e) {
        console.log("Error in fetching category products: ", e.message);
      }
    };
    fetchBeveragesProducts();
  }, []);

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set());

  const { state, dispatch } = useContext(CartContext);

  const onSingleProduct = (productId) => {
    navigate(`/singleproduct/${productId}`);
  };

  const handleAddToCart = async (product) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    };

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      alert("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      alert("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          alert("Product added to the cart!");
        } else {
          alert("Failed to add product to the cart");
        }
      } catch (error) {
        console.error("Error adding product to the cart:", error);
        alert("An error occurred. Please try again.");
      }
    }
  };

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      alert("You need to be logged in to add products to your wishlist.");
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          alert("Product removed from wishlist successfully!");
          setWishlist(prevWishlist => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
        } else {
          alert("Failed to remove product from wishlist.");
        }
      } catch (error) {
        console.error("Error removing product from wishlist:", error);
        alert("An error occurred. Please try again.");
      }
      return;
    }

    try {
      const response = await axios.post(
        `https://krizaar.com/api/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        alert("Product added to wishlist successfully!");
        setWishlist(prevWishlist => new Set(prevWishlist).add(productId));
      } else {
        alert("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `https://krizaar.com/api/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlist();
  }, []);

  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row prod-slider">
            <div className="col-lg-4">
              <div className="spe-box">
                <CountdownTimer />
              </div>
            </div>
            <div className="col-lg-8">
              <Swiper
                breakpoints={{
                  576: {
                    width: 576,
                    slidesPerView: 2,
                  },
                  768: {
                    width: 768,
                    slidesPerView: 3,
                  },
                }}
                spaceBetween={30}
                slidesPerView={2}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                navigation
                className="custom-swiper"
              >
                {beveragesProducts.map((product) => (
                  <SwiperSlide key={product._id} className="custom-swiper-slide">
                    <div className="product-box-1">
                      <div
                        className="product-content"
                        onClick={() => onSingleProduct(product._id)}
                      >
                        <img
                          src={product?.isProductFromAliExpress ? product.product_banner_image : `https://krizaar.com/api/uploads/${product.product_banner_image}`}
                          alt={product.product_name}
                          className="prod-img img-fluid"
                        />
                        <p className="product-title">{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</p>
                        <span>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </span>
                        <p className="rate">
                          {product.offer_price ? `$${product.offer_price}` : product.price ? `$${product.price}` : "Price not available"}
                        </p>
                        <div className="icons">
                          <i
                            className="fas fa-shopping-cart cart-icon"
                            onClick={(e) => {
                              // e.preventDefault();
                              // e.stopPropagation();
                              // handleAddToCart(product);
                            }}
                          ></i>
                          <i
                            className="fas fa-heart wishlist-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleAddToWishlist(product._id);
                            }}
                          ></i>
                          <i
                            className="fa fa-eye eye-icon"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
