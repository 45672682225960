import React, { useEffect, useState } from "react";
import Profile from "../../componenth/profile/Profile";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import axios from "axios";
import Cookies from 'js-cookie';
import { useNavigate, useParams } from "react-router-dom";

import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const EditAddress = () => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [fullName, setFullName] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [locality, setLocality] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [landmark, setLandmark] = useState("");
  const [altmobno, setAltmobno] = useState("");
  const [addtype, setAddtype] = useState("home");

  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get("krizaar-user");

  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }
  }, [userId, accessToken])

  const { addressId } = useParams();

  useEffect(() => {
    const fetchAddressDetails = async () => {
      const response = await axios.post(
        'https://krizaar.com/api/auth/user/address/detail', {
        address: {
          address_id: addressId
        }
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
      );
      // console.log(response.data.AddressData)
      if (response?.data?.AddressData) {
        setFullName(response.data.AddressData.full_name);
        setZipcode(response.data.AddressData.pin_code);
        setLocality(response.data.AddressData.locality);
        setAddress(response.data.AddressData.address_detail);
        setCity(response.data.AddressData.city);
        setState(response.data.AddressData.state);
        setCountry(response.data.AddressData.country);
        setLandmark(response.data.AddressData.landmark);
        setAltmobno(response.data.AddressData.alternate_phone_no);
        setAddtype(response.data.AddressData.address_type);
      }
    }
    if (addressId !== '0') {
      console.log('Hello')
      fetchAddressDetails();
    }
  }, [addressId])

  const addNewAddress = async () => {
    try {
      const response = await axios.post(
        `https://krizaar.com/api/auth/user/create/address`,
        {
          address: {
            user_id: userId,
            full_name: fullName,
            pin_code: zipcode,
            locality: locality,
            address_detail: address,
            country: country,
            state: state,
            city: city,
            landmark: landmark || "",
            alternate_phone_no: altmobno || "",
            address_type: addtype,
          },
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response) {
        alert("Address added successfully");
        navigate('/manageaddress')
      }
    } catch (err) {
      console.error("Error in adding new address:", err);
    }
  };


  const updateAddress = async () => {
    try {
      const response = await axios.post(
        `https://krizaar.com/api/auth/user/update/address`,
        {
          address: {
            address_id: addressId,
            user_id: userId,
            full_name: fullName,
            pin_code: zipcode,
            locality: locality,
            address_detail: address,
            country: country,
            state: state,
            city: city,
            landmark: landmark || "",
            alternate_phone_no: altmobno || "",
            address_type: addtype,
          },
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response) {
        alert("Address updated successfully");
        navigate('/manageaddress')
      }
    } catch (err) {
      console.error("Error in adding new address:", err);
    }
  };

  return (
    <>
      <Header />
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-3">
              <Profile />
            </div>
            <div className="col-lg-9">
              <p>Account {">"} Manage Addresses {addressId === '0' ? '> Add New Address' : '> Edit Address'}</p>
              <div className="form-border">
                <div className="form-row">
                  <label htmlFor="input1">Full Name</label>
                  <input
                    type="text"
                    id="input1"
                    name="input1"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1">Zip Code</label>
                    <input
                      type="text"
                      id="input1"
                      name="input1"
                      value={zipcode}
                      onChange={(e) => setZipcode(e.target.value)}
                    />
                  </span>
                  <span className="inp-form">
                    <label htmlFor="input2">Locality</label>
                    <input
                      type="text"
                      id="input2"
                      name="input2"
                      value={locality}
                      onChange={(e) => setLocality(e.target.value)}
                    />
                  </span>
                </div>
                <div className="form-row">
                  <label htmlFor="input1">Address</label>
                  <textarea
                    id="input1"
                    name="input1"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1">Country</label>
                    <CountrySelect
                      onChange={(e) => {
                        setCountry(e.name);
                        setCountryid(e.id);
                      }}
                    />
                  </span>
                  <span className="inp-form">
                    <label htmlFor="input2">State</label>
                    <StateSelect
                      countryid={countryid}
                      onChange={(e) => {
                        setState(e.name);
                        setstateid(e.id);
                      }}
                    />
                  </span>
                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input1">City/Town/District</label>
                    <CitySelect
                      countryid={countryid}
                      stateid={stateid}
                      onChange={(e) => {
                        setCity(e.name);
                      }}
                    />
                  </span>
                  <span className="inp-form">
                    <label htmlFor="input1">Landmark (Optional)</label>
                    <input
                      type="text"
                      id="input1"
                      name="input1"
                      value={landmark}
                      onChange={(e) => setLandmark(e.target.value)}
                    />
                  </span>

                </div>
                <div className="form-row">
                  <span className="inp-form">
                    <label htmlFor="input2">Alternate Phone No.</label>
                    <input
                      type="text"
                      id="input2"
                      name="input2"
                      value={altmobno}
                      onChange={(e) => setAltmobno(e.target.value)}
                    />
                  </span>
                </div>
                <p className="addr-typ">Address Type</p>
                <label className="radio-btn">
                  <input
                    type="radio"
                    name="address"
                    value="home"
                    checked={addtype === "home"}
                    onChange={(e) => setAddtype(e.target.value)}
                  />
                  Home
                </label>
                <label className="radio-btn">
                  <input
                    type="radio"
                    name="address"
                    value="office"
                    checked={addtype === "office"}
                    onChange={(e) => setAddtype(e.target.value)}
                  />
                  Office
                </label>
                <div className="form-btn">
                  <button
                    className="f-next"
                    type="submit"
                    onClick={addressId === '0' ? addNewAddress : updateAddress}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default EditAddress;
