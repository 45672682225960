import React, { useEffect, useState } from "react";
import Select from "react-select";

const MeasurementUnitDropdown = ({ onUnitChange, index, param, unitList, attributeIndex, unitedit }) => {
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);

  useEffect(()=>{
    setSelectedUnit(unitedit)
  },[unitedit])

  useEffect(()=>{
    const unitSet = () => {
      if(unitList != ''){
        let unitarray = unitList.split(',')
        let unitdata = unitarray.map((unit)=>({
          value: unit,
          label: unit,
        }))
        setUnits(unitdata)
      }
    }
    unitSet();
    if(attributeIndex == index){
      setSelectedUnit(null)
    }
  },[unitList])

  const handleChange = (selectedOption) => {
    setSelectedUnit(selectedOption);
    onUnitChange(index, param, selectedOption);
  };

  return (
    <div>
      <Select
        options={units}
        value={selectedUnit}
        onChange={handleChange}
        isMulti={true}
        placeholder="Search and select a unit"
        isSearchable
      />
      {/* {selectedUnit && (
        <div>
          <p>Selected Unit: {selectedUnit.map((item)=>{return item.label+', '})}</p>
        </div>
      )} */}
    </div>
  );
};

export default MeasurementUnitDropdown;
