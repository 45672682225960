import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import img1 from "../../img/krizaar-about1.jpeg";
import img2 from "../../img/krizaar-about2.jpeg";
import CountUp from "react-countup";

const About = () => {
  useEffect(()=>{
    //import('./WebPanelRoutes.css');
  },[])
  return (
    <>
      <Header />
      <section>
        <div className="container mt-5">
          <h1 className="about-heading">About Us </h1>
          <div className="row mt-5">
            <div className="col-lg-6">
              <h3 className="my-3">
                Shop Smart,
                <span style={{ color: "#C22D57" }}>Shop Securely</span>
              </h3>
              <h5>Tired of the same old e-commerce experience? </h5>
              <p>
                As an online buyer or seller, you deserve a shopping experience
                built on trust, transparency and cutting-edge technology. That's
                why we are Krizaar- a blockchain-powered e-commerce platform
                with features that go beyond the standard online marketplace.
              </p>
              <h5>
                Get reliability and accountability delivered at your doorstep!{" "}
              </h5>
              <p>
                But we're not just about the tech. Our team is made up of real
                people who are passionate about delivering an exceptional
                shopping experience. From our customer service ninjas to our
                lightning-fast fulfillment crew, we're here to make your life
                easier.
              </p>
            </div>
            <div className="col-lg-6">
              <img src={img1} alt="aboutus" className="about-img img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="about2-section">
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-6">
              <img src={img2} alt="aboutus" className="about-img img-fluid" />
            </div>
            <div className="col-lg-6">
              <h3 className="my-3">
                And the
                <span style={{ color: "#C22D57" }}> best part?</span>
              </h3>
              <h5> You can shop with confidence! </h5>
              <p>
                Knowing that your data and payments are 100% secure, you can be
                worry-free about identity theft or fraudulent charges.
              </p>
              <p>Just pure, uninterrupted retail bliss with: </p>
              <li>. Amazing deals and offers every hour </li>
              <li>. Transparent Transactions </li>
              <li>. Secure Payments </li>
              <li>. Empowered Sellers</li>
              <li>. Seamless Shopping </li>
              <li>. Innovative features </li>
            </div>
          </div>
          <div className="row">
            <h5>Ready to explore e-commerce like never before? </h5>
            <p>
              Sign up today and experience the most transparent buyer-seller
              bond. Your future self will thank you!
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row my-5">
            <div className="col-lg-3">
              <div className="about-sellbox">
                <i className="fas fa-cube cube-icon"></i>
                <div className="mt-3">
                  <h1>
                    <CountUp
                      start={0}
                      end={45}
                      duration={4.75}
                      separator=","
                      suffix="M"
                    />
                  </h1>
                  <p>Products for sale</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-sellbox">
                <i className="fas fa-cube cube-icon"></i>
                <div className="mt-3">
                  <h1>
                    <CountUp
                      start={0}
                      end={1.8}
                      duration={4.75}
                      separator=","
                      suffix="M"
                    />
                  </h1>
                  <p>Sellers Active on Krizaar</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-sellbox">
                <i className="fas fa-cube cube-icon"></i>
                <div className="mt-3">
                  <h1>
                    <CountUp
                      start={0}
                      end={30.6}
                      duration={4.75}
                      separator=","
                      suffix="M"
                    />
                  </h1>
                  <p>Buyers Activie on Krizaar</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="about-sellbox">
                <i className="fas fa-cube cube-icon"></i>
                <div className="mt-3">
                  <h1>
                    <CountUp
                      start={0}
                      end={2.5}
                      duration={4.75}
                      separator=","
                      suffix="M"
                    />
                  </h1>
                  <p>Annual gross sales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
