import React, { useEffect } from "react";


const Dashboard = () => {
  useEffect(() => {
    import('../AdminPanelRoutes.css');
  }, [])
  return (
    <>
      <section id="admin-main-content" className="mb-3" >
        <div className="container-fuild">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Dashboard</h5>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Shipping Address</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>Lorem Ipsum is simply</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Shipping Address</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>Lorem Ipsum is simply</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Shipping Address</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>Lorem Ipsum is simply</p>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-3">
          <section className="custom-cart-panel mb-3">
            <div className="container-fuild">
              <div className="row">
                <div className="vendor-top col-12">
                  <h5 className="title-v">Shipping Address</h5>
                </div>
              </div>
              <div className="addressBilling mb-2">
                <p>Lorem Ipsum is simply</p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-8">
            <section className="custom-cart-panel mb-3">
              <div className="container-fuild">
                <div className="row">
                  <div className="vendor-top col-12">
                    <h5 className="title-v">Customer Details</h5>
                  </div>
                </div>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <div className="col-md-4">
            <section className="custom-cart-panel mb-3">
              <div className="container-fuild">
                <div className="row">
                  <div className="vendor-top col-12">
                    <h5 className="title-v">Shipping Address</h5>
                  </div>
                </div>
                <div className="addressBilling mb-2">
                <p>Lorem Ipsum is simply</p>
                  <p>city</p>
                  <p>locality</p>
                  <p>India</p>
                </div>
              </div>
            </section>
            <section className="custom-cart-panel mb-3">
              <div className="container-fuild">
                <div className="row">
                  <div className="vendor-top col-12">
                    <h5 className="title-v">Billing Address</h5>
                  </div>
                </div>
                <div className="addressBilling mb-2">
                  <p>Lorem Ipsum is simply</p>
                  <p>city</p>
                  <p>locality</p>
                  <p>India</p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">Product Listing</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
          <div className="col-md-6">
            <div className="custom-cart-panel mb-3">
              <div className="vendor-top col-12">
                <h5 className="title-v">Orders Listing</h5>
              </div>
              <div className="mlr-10 table-responsive mb-4">
                <table className="tableGap">
                  <thead>
                    <tr>
                      <th><input type="checkbox" /></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>QTY</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                    <tr>
                      <td>
                        <input type="checkbox" />
                      </td>
                      <td>iPhone 13 Pro Max</td>
                      <td>Rs. 54000</td>
                      <td>
                        1
                      </td>
                      <td>Rs. 54000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>

  );
};

export default Dashboard;
