import { createThirdwebClient } from "thirdweb";
import { ConnectButton } from "thirdweb/react";
import { darkTheme } from "thirdweb/react";
import {
    inAppWallet,
    createWallet,
  } from "thirdweb/wallets";
import { optimism } from "thirdweb/chains"; // Use Optimism for chain

const client = createThirdwebClient({
  clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID, // Uses your .env value
});

const wallets = [
  inAppWallet({
    auth: {
      options: [
        "google",
        "facebook",
        "passkey",
        "phone",
        "email",
        "apple",
      ],
    },
  }),
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("me.rainbow"),
  createWallet("io.zerion.wallet"),
  createWallet("com.binance"),
];

function ThirdwebConnectButton() {
  return (
    <ConnectButton
      client={client}
      wallets={wallets}
      theme={darkTheme({
        colors: {
          accentText: "#ad33ff",
          modalBg: "#190000",
        },
      })}
      connectButton={{ label: "Sign in" }}
      connectModal={{
        size: "compact",
        title: "Sign in to Krizaar",
        titleIcon:
          "https://i.postimg.cc/2yYSXnzH/favicon.png",
        showThirdwebBranding: false,
        termsOfServiceUrl:
          "https://krizaar.com/terms-condition",
        privacyPolicyUrl:
          "https://krizaar.com/privacypolicy",
      }}
      accountAbstraction={{
        chain: optimism,
        sponsorGas: true,
      }}
    />
  );
}

export default ThirdwebConnectButton;
