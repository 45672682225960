import React, { useState } from 'react'
import aadhar from '../../img/aadhar.png'
import pan from '../../img/pan.jpg'
import shop1 from '../../img/shop1.webp'
import shop2 from '../../img/shop2.jpg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import shop3 from '../../img/shop3.avif'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const VendorProfile = () => {
    const [dropdownList, setdropdownList] = useState(['Approve', 'Not Approve'])
    const [openModal, setOpenModal] = useState(false);

    const handleModalOpen = (option) => {
        if (option !== 'Approve') {
            setOpenModal(true)
        }
    }

    const handleModal = () => {
        setOpenModal(false)
    }

    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Documents</h5>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <h5 className='mb-3'>Shop Details <span className='badge'>Approve</span> </h5>
                        <h5><span className='spanSpace'>Shop Name:-</span>Ranjeet Kirana Store</h5>
                        <h6><span className='spanSpace'>Shop Location:-</span>Near Bus stand, Ajitnagar, Lucknow U.P. 235610</h6>
                        <h6><span className='spanSpace'>GST No:-</span>UP32100002354</h6>
                        <h6>Shop Images</h6>
                        <div className="vendorProfile">
                            <div className=''>
                                <img src={shop1} />
                            </div>
                            <div className=''>
                                <img src={shop2} />
                            </div>
                            <div className=''>
                                <img src={shop3} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <h5 className='mb-3'>Personal Document <span className='badge'>Approve</span> </h5>
                        <h5><span className='spanSpace'>Name as per ID:-</span>Ranjeet Singh</h5>
                        <h6><span className='spanSpace'>Location as per ID:-</span>Near Bus stand, Ajitnagar, Lucknow U.P. 235610</h6>
                        <h6><span className='spanSpace'>Aadhar No:- </span>6522-7911-3650</h6>
                        <h6>ID Images</h6>
                        <div className="vendorProfile">
                            <div className=''>
                                <img src={aadhar} />
                            </div>
                            <div className=''>
                                <img src={aadhar} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='custom-cart-panel mb-3'>
                        <div className="d-flex justify-content-between">
                            <h5 className='mb-3'>Personal Document </h5>
                            <div className="btn-group dropleft">
                                <button className="trash-icon" data-bs-toggle="dropdown" aria-expanded="false">
                                    <MoreVertIcon />
                                </button>
                                <ul className="dropdown-menu">
                                    {dropdownList.map((option, index) => (
                                        <li className="text-capitalize" key={option} onClick={()=>handleModalOpen(option)}>
                                            <a className="dropdown-link" href='javascript:void(0)'>{option}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <h5><span className='spanSpace'>Name as per ID:-</span>Ranjeet Singh</h5>
                        <h6><span className='spanSpace'>Location as per ID:-</span>Near Bus stand, Ajitnagar, Lucknow U.P. 235610</h6>
                        <h6><span className='spanSpace'>PAN No:- </span>JSPSA7733P</h6>
                        <h6>ID Images</h6>
                        <div className="vendorProfile">
                            <div className=''>
                                <img src={pan} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='custom-cart-panel mb-3'>
                <div className="row">
                    <div className="vendor-top">
                        <h5 className="title-v">Change Password</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className="mb-2">
                            <label for="exampleFormControlInput1" className="form-label">Password</label>
                            <input type="text" className="form-control" id="categoryNameId" placeholder="password" />
                        </div>
                        <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label">Confirm Password</label>
                            <input type="text" className="form-control" id="categoryNameId" placeholder="confirm password" />
                        </div>
                        <button className="trash-icon px-3 py-2" >Change Password</button>
                    </div>
                </div>
            </div>
            <Dialog
                open={openModal}
                keepMounted
                maxWidth={'xs'}
                onClose={handleModal}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Vendor Approvel Cancel"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Please enter a reason to cancel this approvel...
                    </DialogContentText>
                    <div className="mb-3">
                        <label for="exampleFormControlInput1" className="form-label">Reason for cancel </label>
                        <textarea rows={3} type="text" className="form-control" id="categoryNameId" placeholder="Enter reason for cancel" />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="trash-icon px-3 py-2" onClick={handleModal}>Cancel</button>
                    <button className="edit-btn px-3 py-2" onClick={handleModal}>Continue</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default VendorProfile;