import React, { useState, useEffect } from "react";
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

const SnackBar = ({openTost, tostMessage, type}) => {
    const [tostShow, settostShow] = useState(openTost);

    useEffect(() => {
        settostShow(openTost);
    }, [openTost]);

    const handleClose = () => {
        settostShow(false);
    };

    const vertical = 'top';
    const horizontal = 'right';

    let backgroundColor;
    switch (type) {
        case 'success':
            backgroundColor = 'green';
            break;
        case 'error':
            backgroundColor = 'red';
            break;
        case 'warning':
            backgroundColor = 'orange';
            break;
        case 'info':
            backgroundColor = 'blue';
            break;
        default:
            backgroundColor = 'grey';
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={tostShow}
            onClose={handleClose}
            message={tostMessage}
            key={vertical + horizontal}
            ContentProps={{
                style: {
                    backgroundColor,
                    color: 'white',
                }
            }}
        />
    );
};

SnackBar.propTypes = {
    openTost: PropTypes.bool.isRequired,
    tostMessage: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default SnackBar;
