import React, { useEffect, useState } from "react";
// import "../../dashboard.css";
import Categories from "../../add/Categories";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BrandDropdown from "../../add/BrandDropdown";
import BrandPopup from "../BrandPopup";
import UnitPopup from "../UnitPopup";
import Tooltip from '@mui/material/Tooltip';
import MeasurementParameterDropdown from "../../add/ParameterDropdown";
import MeasurementUnitDropdown from "../../add/MeasurmentDropdown";
import axios from "axios";
import ParameterPopup from "../ParameterPopup";
import InfoIcon from '@mui/icons-material/Info';
import { REACT_BASE_PATH } from "../../../../api";
import { useLocation, useNavigate } from 'react-router-dom';
import SnackBar from "../../helper/SnackBar";

const Addnewproduct = ({ editType = 'Add' }) => {
  const location = useLocation();
  const navigate = useNavigate()
  const editDataValue = location.state || {};
  console.log('===========================', editDataValue);

  const [editValue, setEditvalue] = useState(editDataValue)
  useEffect(() => {
    if (editType !== 'Edit') {
      setProductName('');
      setParamalink('')
      setDescription('')
      setShortDescription('')
      setSelectedBrand(null)
      setSelectedSubCategories([])
      setSelectedCategories([])
      setSelectedChildCategories([])
      setPrice('')
      setofferPrice('')
      setCurrency('inr')
      setStockQty('')
      setUnitList('')
      setattributeIndex(null)
      setisEditColorGallery(false)
      setisEditColorImage(false)
      setisEditBannerImage(false)
      setisEditgalleryImage(false)
      setAttriUnit([
        {
          id: 1,
          attribute: '',
          name: '',
          unit: []
        }
      ])
      setColorSpecifications([
        { _id: 1, color: "", image: null, gallery: [], stock: '' },
      ])
      setSpecifications([
        { _id: 1, specfication_heading: "", specification_detail: "" },
      ])
      setImage(null)
      setGallery('');
      settags('')
      setbestQuality(false)
    }
  }, [editType])
  const [productName, setProductName] = useState(editValue?.product_name || "");
  const [paramalink, setParamalink] = useState(editValue?.product_permalink || "");
  const [description, setDescription] = useState(editValue?.product_detail || "");
  const [shortDescription, setShortDescription] = useState(editValue?.product_short_disc || "");
  const [selectedBrand, setSelectedBrand] = useState(editValue?.brand_details?._id ? { value: editValue?.brand_details?._id, label: editValue?.brand_details?.brand_name } : null);
  const [selectedSubCategories, setSelectedSubCategories] = useState(editValue?.category_details_level1?._id ? { value: editValue.category_details_level1._id, label: editValue.category_details_level1.category_name } : []);
  const [selectedCategories, setSelectedCategories] = useState(editValue?.category_details_level0?._id ? { value: editValue.category_details_level0._id, label: editValue.category_details_level0.category_name } : []);
  const [selectedChildCategories, setSelectedChildCategories] = useState(editValue?.category_details_level2?._id ? { value: editValue.category_details_level2._id, label: editValue.category_details_level2.category_name } : []);
  const [price, setPrice] = useState(editValue?.price || '');
  const [offerPrice, setofferPrice] = useState(editValue?.offer_price || '');
  const [Currency, setCurrency] = useState(editValue?.currency_type || 'inr');
  const [StockQty, setStockQty] = useState(editValue?.product_total_stock || '');
  const [unitList, setUnitList] = useState('')
  const [attributeIndex, setattributeIndex] = useState(null)
  const [isEditColorGallery, setisEditColorGallery] = useState(editValue?.product_color_image?.length ? true : false);
  const [isEditColorImage, setisEditColorImage] = useState(editValue?.product_color_image?.length ? true : false);
  const [isEditBannerImage, setisEditBannerImage] = useState(editValue?.product_banner_image ? true : false);
  const [isEditgalleryImage, setisEditgalleryImage] = useState(editValue?.product_gallery_image?.length ? true : false);
  const [brandAdd, setbrandAdd] = useState(false)
  const [UnitAll, setUnitAll] = useState(false);
  const [Added, setAdded] = useState(false);
  const [bestQuality, setbestQuality] = useState(editValue?.best_quality);
  const [tags, settags] = useState(editValue?.tags?.length ? editValue?.tags.join(',') : '');
  const [attriUnit, setAttriUnit] = useState([
    {
      id: 1,
      attribute: '',
      name: '',
      unit: []
    }
  ]);
  const [openTost, setopenTost] = useState(false);
  const [tostMessage, setostMessage] = useState('');
  const [type, setType] = useState('');
  const [offerDisable, setofferDisable] = useState(editValue?.offer_price ? false : true)
  const [adding, setadding] = useState(false)

  useEffect(() => {
    const setAttriunitEdit = () => {
      if (editValue?.product_measurment_parameter_id?.length) {
        setUnitAll(true)
        let setAt = []
        editValue?.product_measurment_parameter_id.map((item, index) => {
          let attr = { value: item.attribute_id, label: item.attribute_name }
          let units = []
          item.unit.split(',').map((unit) => {
            units.push({ value: unit, label: unit })
          })
          setAt.push({
            id: item._id,
            attribute: attr,
            name: '',
            unit: units
          })
        })
        setAttriUnit(setAt)
      }
    }
    setAttriunitEdit()
  }, [])
  const [colorSpecifications, setColorSpecifications] = useState(editValue?.product_color_image?.length ? editValue?.product_color_image : [
    { _id: 1, color: "", image: null, gallery: [], stock: '' },
  ]);
  const [specifications, setSpecifications] = useState(editValue?.product_specfication_detail?.length ? editValue?.product_specfication_detail : [
    { _id: 1, specfication_heading: "", specification_detail: "" },
  ]);
  const [image, setImage] = useState(editValue?.product_banner_image || null);
  const [gallery, setGallery] = useState(editValue?.product_gallery_image || []);

  const addPriceDetail = () => {
    setAttriUnit([
      ...attriUnit,
      {
        id: attriUnit.length + 1,
        attribute: '',
        name: '',
        unit: []
      },
    ]);
  };

  const offerValidationCheck = (e) => {
    setofferPrice(e.target.value);
    validatePrices(price, e.target.value)
  }

  const priceValidationCheck = (e) => {
    setPrice(e.target.value)
    if(e.target.value == ''){
      setofferDisable(true)
    }else{
      setofferDisable(false)
    }
    // validatePrices(e.target.value, offerPrice)
  }

  const validatePrices = (realPrice, offerPrices) => {
    if (realPrice && offerPrices) {
      if (parseInt(offerPrices) >= parseInt(realPrice)) {
        setopenTost(true);
        setostMessage('price can not be equal or less than offer price');
        setType('error')
      } else {
        setopenTost(false)
        setostMessage('')
        setType('')
      }
    }
  };

  const addColorSpecification = () => {
    setColorSpecifications([
      ...colorSpecifications,
      {
        _id: colorSpecifications.length + 1,
        color: "",
        image: null,
        gallery: [],
        stock: ''
      },
    ]);
  };

  const addSpecification = () => {
    setSpecifications([
      ...specifications,
      {
        _id: specifications.length + 1,
        specfication_heading: "",
        specification_detail: "",
      },
    ]);
  };

  const handleDescriptionChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleAttributsChange = (index, field, value) => {
    setattributeIndex(index)
    const updatedattriUnit = [...attriUnit];
    updatedattriUnit[index][field] = value;
    setAttriUnit(updatedattriUnit);
  };

  const handleColorSpecificationChange = (index, field, value) => {
    const updatedColorSpecifications = [...colorSpecifications];
    updatedColorSpecifications[index][field] = value;
    setColorSpecifications(updatedColorSpecifications);
  };

  const handleSpecificationChange = (index, field, value) => {
    const updatedSpecifications = [...specifications];
    updatedSpecifications[index][field] = value;
    setSpecifications(updatedSpecifications);
  };

  const formValidationCheck = () => {
    if (!productName || productName == '') {
      setopenTost(true)
      setostMessage('Please enter Product Name')
      setType('error')
      return false;
    }
    if (!paramalink || paramalink == '') {
      setopenTost(true)
      setostMessage('Please enter Paramalink')
      setType('error')
      return false;
    }
    if (!description || description == '') {
      setopenTost(true)
      setostMessage('Please enter description')
      setType('error')
      return false;
    }
    if (!selectedBrand || selectedBrand.length == 0) {
      setopenTost(true)
      setostMessage('Please select Brand Name')
      setType('error')
      return false;
    }
    if (!tags || selectedBrand == '') {
      setopenTost(true)
      setostMessage('Please select Tag Name')
      setType('error')
      return false;
    }
    if (!price || price == '') {
      setopenTost(true)
      setostMessage('Please enter price')
      setType('error')
      return false;
    }
    if (!StockQty || StockQty == '') {
      setopenTost(true)
      setostMessage('Please enter Product stock quantity')
      setType('error')
      return false;
    }


    let isColorValid = true;
    let isSpecValid = true;
    let colorstocktotal = 0

    for (let index = 0; index < colorSpecifications.length; index++) {
      let colorSpec = colorSpecifications[index];
      if (colorSpec.color && (!colorSpec.stock || !colorSpec.image)) {
        setopenTost(true);
        setostMessage('Please select an image and enter the stock quantity for the color.');
        setType('error');
        isColorValid = false;
        break;
      }
    }
    if (!isColorValid) {
      return false;
    }

    for (let index = 0; index < colorSpecifications.length; index++) {
      let colorSpec = colorSpecifications[index];
      if (colorSpec.stock) {
        colorstocktotal = colorstocktotal + parseInt(colorSpec.stock)
      }
    }
    if (parseInt(colorstocktotal) > parseInt(StockQty)) {
      setopenTost(true)
      setostMessage('Please enter valid avilable stocks')
      setType('error')
      return false;
    }

    for (let index = 0; index < specifications.length; index++) {
      let Spec = specifications[index];
      if (Spec.specfication_heading !== '' && Spec.specification_detail == '') {
        setopenTost(true);
        setostMessage('Please enter specification detail');
        setType('error');
        isSpecValid = false;
        break;
      }
    }

    if (!isSpecValid) {
      return false;
    }

    if (!selectedCategories || selectedCategories.length == 0) {
      setopenTost(true)
      setostMessage('Please select category')
      setType('error')
      return false;
    }
    if (!image || image.length == 0) {
      setopenTost(true)
      setostMessage('Please select Product image')
      setType('error')
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    let result = formValidationCheck()
    setTimeout(() => {
      setopenTost(false)
    }, 3000);
    if (result) {
      setadding(true)
      const formData = new FormData();
      formData.append("category_id_level0", selectedCategories?.value);
      if (selectedSubCategories?.value) {
        formData.append("category_id_level1", selectedSubCategories?.value);
      }
      if (selectedChildCategories?.value) {
        formData.append("category_id_level2", selectedChildCategories?.value);
      }
      formData.append("product_name", productName);
      formData.append("brand_id", selectedBrand?.value);
      formData.append("vendor_id", "664f76ced2a28de7d549a396");
      formData.append("product_total_stock", StockQty);
      formData.append("product_detail", description);
      formData.append("product_short_disc", shortDescription);
      formData.append("product_permalink", paramalink);
      formData.append("price", price);
      formData.append("offer_price", offerPrice);
      formData.append("currency_type", Currency);
      formData.append('tags', tags);
      formData.append('best_quality', bestQuality);
      attriUnit?.forEach((item, index) => {
        if (item?.attribute && item?.attribute?.value) {
          formData.append(
            `product_measurment_parameter_id[${index}][attribute_id]`,
            item?.attribute?.value
          );
          formData.append(
            `product_measurment_parameter_id[${index}][attribute_name]`,
            item?.attribute?.label
          );
        }
        if (item?.unit) {
          let units = item?.unit?.map((unit) => {
            return unit?.label;
          })
          formData.append(
            `product_measurment_parameter_id[${index}][unit]`,
            units.join()
          );
        }
      })

      colorSpecifications.forEach((colorSpec, index) => {
        if (colorSpec.color) {
          formData.append(
            `product_color_image[color][${index}]`,
            colorSpec.color
          );
        } else {
          formData.append(`product_color_image[color][${index}]`, "");
        }
        if (colorSpec.image) {
          formData.append(
            `product_color_image[image][${index}]`,
            colorSpec.image
          );
        }
        if (colorSpec.gallery.length > 0) {
          colorSpec.gallery.forEach((file, i) => {
            formData.append(`product_color_image[gallery][${index}][]`, file);
          });
        }
        if (colorSpec.stock) {
          formData.append(
            `product_color_image[stock][${index}]`,
            colorSpec.stock
          );
        }
      });

      specifications.forEach((spec, index) => {
        if (spec.specfication_heading) {
          formData.append(
            `product_specfication_detail[${index}][specfication_heading]`,
            spec.specfication_heading
          );
        }
        if (spec.specification_detail) {
          formData.append(
            `product_specfication_detail[${index}][specification_detail]`,
            spec.specification_detail
          );
        }
      });

      if (image) {
        formData.append("product_banner_image", image);
      }
      if (gallery.length > 0) {
        for (let i = 0; i < gallery.length; i++) {
          formData.append("product_gallery_image[]", gallery[i]);
        }
      }
      if (editType == 'Edit') {
        formData.append("id", editValue?._id)
      }

      // console.log('==selectedCategories==', selectedCategories);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ':- ' + pair[1]);
      // }

      try {
        let path = editType == 'Add' ? '/product/add/product' : '/product/productupdate'
        const response = await axios.post(
          `${REACT_BASE_PATH}` + path,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setadding(false)
        navigate('/admin/allproducts')
        // Reset form here if necessary
      } catch (error) {
        setopenTost(true);
        setostMessage('Please fill all the feilds');
        setType('error');
        setadding(false)
        console.error("There was an error submitting the form!", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
          console.error("Response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Request data:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
      }
    }
  };

  return (
    <>
      <SnackBar openTost={openTost} tostMessage={tostMessage} type={type} />
      <section id="admin-main-content" className="mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top col-md-6">
              <h5 className="title-v">{editType == 'Add' ? 'Add' : 'Edit'} Product</h5>
            </div>
            <div className="mt-2 text-end col-md-6">
              <button
                type="button"
                className="upload-btn"
              >
                Import CSV
              </button>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row">
          <div className="col-sm-9 col-12">
            <div className="custom-cart-panel">
              <div className="row">
                <div className="col-sm-6 col-12">
                  <label htmlFor="product">Product Name<span className="required">*</span></label>
                  <input
                    type="text"
                    id="product"
                    value={productName}
                    className="prod-input form-control"
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <label htmlFor="permalink">Permalink<span className="required">*</span></label>
                  <div className="input-group">
                    <span className="input-group-text form-control" id="basic-addon3">
                      https://krizaar.com/
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={paramalink}
                      onChange={(e) => setParamalink(e.target.value)}
                      id="basic-url"
                      aria-describedby="basic-addon3 basic-addon4"
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-12 mb-2">
                  <label htmlFor="shortDescription">Short Description</label>
                  <textarea
                    id="shortDescription"
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                    className="prod-input form-control h-75"
                  />
                </div>
                <div className="col-sm-12 col-12 mb-3">
                  <div className="product-description">
                    <label>Product Description<span className="required">*</span></label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={description}
                      onChange={handleDescriptionChange}
                    />
                  </div>
                </div>
                <div className="my-3">
                  <label className="d-flex">
                    <span>Select Brand<span className="required">*</span></span>
                    <span className="ms-2">
                      <BrandPopup setbrandAdd={setbrandAdd} />
                    </span>
                  </label>
                  <BrandDropdown onBrandChange={setSelectedBrand} editBrandDetail={selectedBrand} brandAdd={brandAdd} />
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="my-3">
                      <label className="d-flex">
                        <span>Tags<span className="required">*</span> <span><Tooltip title="this will show your product as recomended product" placement="right-start"><InfoIcon /></Tooltip></span></span>
                      </label>
                      <input
                        type="text"
                        value={tags}
                        placeholder="tags should be commom seprated"
                        className=" form-control"
                        onChange={(e) =>
                          settags(
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="my-3">
                      <label className="d-flex">
                        <span>Best Quality</span>
                      </label>
                      <input
                        type="checkbox"
                        checked={bestQuality}
                        onChange={() =>
                          setbestQuality(
                            !bestQuality
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <h5 class="title-v my-3">Product Measurement and Price Detail</h5>
                <div className="add-form mt-3 addsec">
                  <button type="button" onClick={addPriceDetail}>
                    +
                  </button>
                </div>
                {attriUnit.map((item, index) => {
                  return (<div key={index + 'key'} className="row">
                    <div className="col-lg-6 mt-3">
                      <label className="d-flex">
                        <span>Select Attribute</span>
                        {index == 0 && <span className="ms-2 d-flex">
                          <ParameterPopup setAdded={setAdded} /><span><Tooltip title="this will visible as key feature of your product " placement="right-start"><InfoIcon /></Tooltip></span>
                        </span>}
                      </label>
                      <MeasurementParameterDropdown
                        onParameterChange={handleAttributsChange}
                        index={index}
                        param={'attribute'}
                        setUnits={setUnitList}
                        attriUnit={item.attribute}
                        Added={Added}
                        UnitAll={UnitAll}
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <label className="d-flex">
                        <span>Select Unit</span>
                        {/* <span className="ms-2">
                          <UnitPopup />
                        </span> */}
                      </label>
                      <MeasurementUnitDropdown onUnitChange={handleAttributsChange} index={index} param={'unit'}
                        unitList={unitList} attributeIndex={attributeIndex} unitedit={item.unit} />
                    </div>
                  </div>)
                })}

                <div className="row">
                  <div className="col-sm-3 col-12">
                    <label>
                      Price<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="price"
                      value={price}
                      className=" form-control"
                      onChange={(e) =>
                        priceValidationCheck(e)
                      }
                    />
                  </div>
                  <div className="col-sm-3 col-12">
                    <label>
                      Offer Price
                    </label>
                    <input
                      type="number"
                      placeholder="offer price"
                      disabled={offerDisable}
                      value={offerPrice}
                      className=" form-control"
                      onChange={(e) => {
                        offerValidationCheck(e)
                      }
                      }
                    />
                  </div>
                  <div className="col-sm-3 col-12">
                    <label>
                      Currency<span className="required">*</span>
                    </label>
                    <select
                      value={Currency}
                      className=" form-control"
                      onChange={(e) =>
                        setCurrency(
                          e.target.value
                        )
                      }
                    >
                      <option value="inr">INR</option>
                      <option value="usd">US Dollar</option>
                    </select>
                  </div>
                  <div className="col-sm-3 col-12">
                    <label>
                      Product Stock Quantity<span className="required">*</span>
                    </label>
                    <input
                      type="number"
                      value={StockQty}
                      className=" form-control"
                      onChange={(e) =>
                        setStockQty(
                          e.target.value
                        )
                      }
                    />
                  </div>
                </div>
                <hr className="mt-4"></hr>
                <h5 class="title-v mt-2">Color Specifications</h5>
                <div className="add-form mt-3 addsec">
                  <button type="button" onClick={addColorSpecification}>
                    +
                  </button>
                </div>
                {colorSpecifications.map((colorSpec, index) => (
                  <div key={colorSpec._id} className="form-container1">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <label htmlFor={`color-${colorSpec._id}`}>
                          Colour name
                        </label>
                        <input
                          type="text"
                          value={colorSpec.color}
                          className="form-control"
                          onChange={(e) =>
                            handleColorSpecificationChange(
                              index,
                              "color",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <label htmlFor={`stock-${colorSpec.id}`}>
                          Colour Wise Stock
                        </label>
                        <input
                          type="text"
                          value={colorSpec.stock}
                          className="form-control"
                          onChange={(e) =>
                            handleColorSpecificationChange(
                              index,
                              "stock",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <label htmlFor={`image-${colorSpec.id}`}>
                          Colour image
                        </label>
                        {isEditColorImage && colorSpec.image && <img src={REACT_BASE_PATH + '/uploads/' + colorSpec.image} className="editImage" />}
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => (
                            setisEditColorImage(false),
                            handleColorSpecificationChange(
                              index,
                              "image",
                              e.target.files[0]
                            ))
                          }
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <label htmlFor={`gallery-${colorSpec.id}`}>
                          Colour gallery image
                        </label>
                        {isEditColorGallery && colorSpec.gallery.length > 0 && colorSpec.gallery.map((image) => { return (<img src={REACT_BASE_PATH + '/uploads/' + image} className="editImage" />) })}
                        <input
                          type="file"
                          className="form-control"
                          multiple
                          onChange={(e) => (
                            setisEditColorGallery(false),
                            handleColorSpecificationChange(
                              index,
                              "gallery",
                              Array.from(e.target.files)
                            )
                          )
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <hr className="mt-4"></hr>
                <div className="specifications">
                  <div className="addsec mt-3">
                    <button type="button" onClick={addSpecification}>
                      +
                    </button>
                  </div>
                  <h5 class="title-v mt-2">Specifications</h5>
                  <div className="form-container1">
                    {specifications.map((spec, index) => (
                      <div key={spec._id}>
                        <div className="row mt-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor={`specName-${spec._id}`}>
                                Specification Heading
                              </label>
                              <input
                                type="text"
                                className="speci-name form-control"
                                value={spec.specfication_heading}
                                onChange={(e) =>
                                  handleSpecificationChange(
                                    index,
                                    "specfication_heading",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="product-description">
                            <label>Specification Description</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={spec.specification_detail}
                              onChange={(event, editor) =>
                                handleSpecificationChange(
                                  index,
                                  "specification_detail",
                                  editor.getData()
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="upload-btn"
                    onClick={handleSubmit}
                  >
                    {editType == 'Add' ? adding ? 'Adding your Product':'Add' : adding ? 'Updating':'Update'} Product
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-12">
            <div className="custom-cart-panel">
              <Categories
                selectedCategory={setSelectedCategories}
                selectedSubCategory={setSelectedSubCategories}
                selectedChildCategory={setSelectedChildCategories}
                category_details_level0={selectedCategories}
                category_details_level1={selectedSubCategories}
                category_details_level2={selectedChildCategories}
              />
              <hr></hr>
              <div className="mb-3">
                <h6 className="title-v my-3">Add Product Image<span className="required">*</span></h6>
                {isEditBannerImage && <img src={REACT_BASE_PATH + '/uploads/' + image} className="editImage" />}
                <label htmlFor="fileUploadImage pt-3">Uploade Image</label>
                <input
                  type="file"
                  name="fileToUploadImage"
                  id="fileUploadImage"
                  className="form-control"
                  onChange={(e) => (setisEditBannerImage(false), setImage(e.target.files[0]))}
                />
              </div>
              <hr></hr>
              <div className="mb-3">
                <h6 className="title-v my-3">Add Product Gallery</h6>
                {isEditgalleryImage && gallery.map((gellery) => { return (<img src={REACT_BASE_PATH + '/uploads/' + gellery} className="editImage" />) })}
                <label htmlFor="fileUploadGallery">Upload gallery</label>
                <input
                  type="file"
                  name="fileToUploadGallery"
                  id="fileUploadGallery"
                  className="form-control"
                  onChange={(e) => (setisEditgalleryImage(false), setGallery(Array.from(e.target.files)))}
                  multiple
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Addnewproduct;
