import React from "react";
import Countdown from "react-countdown";

const CountdownTimer = () => {
  const endDate = new Date("2024-09-10T00:00:00");

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Offer Ended!</span>;
    } else {
      return (
        <div className="">
          <h2 className="counter-heading">
            Special offers <br />
            up to{" "}
            <span
              style={{
                background: "linear-gradient(180deg, #D34276 0%, #FE8AC1 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              50%{" "}
            </span>
            off
          </h2>
          <div className="Harry-up">
            <h3
              style={{ fontSize: "28px", color: "#fff", paddingBottom: "10px" }}
            >
              Harry up! offer ends in
            </h3>
            <div
              style={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <div
                className="days"
                style={{
                  background:
                    "linear-gradient(180deg, #FF0663 0%, #DC0355 100%)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>{days}</div>
                <div>DAYS</div>
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #FF0663 0%, #DC0355 100%)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>{hours}</div>
                <div>HOURS</div>
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #FF0663 0%, #DC0355 100%)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>{minutes}</div>
                <div>MIN</div>
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #FF0663 0%, #DC0355 100%)",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <div>{seconds}</div>
                <div>SEC</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <Countdown date={endDate} renderer={renderer} />;
};

export default CountdownTimer;
