import React, { useState } from 'react'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import VendorProducts from './vendorProducts';
import VendorAccount from './vendorAccounts';
import VendorProfile from './vendorProfile';
import VendorOrders from './VendorOrders';
import profileIcon from '../../img/woman.png'
import { useLocation } from 'react-router-dom';

const VendorDetails = () => {
    const location = useLocation();
    const activetab = location.state || {};
    const [value, setValue] = useState(activetab.toString());
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <section className="custom-cart-panel mb-3">
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">Vendor Details</h5>
                        </div>
                    </div>
                </div>
                <div className="card-header border-top px-0 pt-4 py-0">
                    <div className="vendorProfile">
                        <div className=''>
                            <img src={profileIcon} />
                        </div>
                        <div className='flex-grow-1'>
                            <h5 className='mb-3'>Ranjeet Singh <span className='badge'>Active</span> </h5>
                            <h5><span className='spanSpace'>ID:-</span>#vendorId1234</h5>
                            <h6><span className='spanSpace'>Email ID:- </span>Ranjeet.Singh@gmail.com</h6>
                            <h6><span className='spanSpace'>Mobile No:-</span>+91-9876543210</h6>
                            <h6><span className='spanSpace'>Role:-</span>Vendor</h6>
                        </div>
                    </div>
                </div>
            </section>
            <div>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Profile" value="1" />
                            <Tab label="Accounts" value="2" />
                            <Tab label="Products" value="3" />
                            <Tab label="Orders" value="4" />
                        </Tabs>
                    </Box>
                </TabContext>
            </div>
            <TabContext value={value}>
                <TabPanel value="1">
                    <VendorProfile />
                </TabPanel>
                <TabPanel value="2">
                    <VendorAccount />
                </TabPanel>
                <TabPanel value="3">
                    <VendorProducts />
                </TabPanel>
                <TabPanel value="4">
                    <VendorOrders />
                </TabPanel>
            </TabContext>
        </>
    )
}

export default VendorDetails