import React, { useContext, useEffect, useMemo, useState } from 'react';
import Header from '../../../componenth/header/Header'
import Footer from '../../../componenth/header/Footer'
import { CheckoutContext } from './CheckoutContext';
import Cookies from 'js-cookie'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const Checkout = () => {
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [currentStep, setCurrentStep] = useState(2);
  const [selectedAddressOption, setSelectedAddressOption] = useState('new');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [newAddress, setNewAddress] = useState({
    name: '',
    phone: '',
    zip: '',
    locality: '',
    address: '',
    city: '',
    state: '',
    landmark: '',
    alternatePhone: '',
    addressType: '',
    country: ''
  });

  const [userDetails, setUserDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobno: '',
    image: ''
  })
  const [existingAddresses, setExistingAddresses] = useState([]);
  const { state, dispatch } = useContext(CheckoutContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allAvailableTaxes, setAllAvailableTaxes] = useState([]);
  const [TAX_RATE, setTAX_RATE] = useState(0)
  const [totalPriceBeforeTax, setTotalPriceBeforeTax] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [randomNumber, setRandomNumber] = useState(null);
  const [randomDiscountAmount, setRandomDiscountAmount] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [roundedGrandTotal, setRoundedGrandTotal] = useState(null);
  const [roundToNearest, setRoundToNearest] = useState(false);

  const navigate = useNavigate();


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmLogout = () => {
    Cookies.remove('krizaar-user');
    localStorage.removeItem('userId');
    localStorage.removeItem('checkoutState');
    localStorage.removeItem('cartState');
    localStorage.removeItem('userId');
    closeModal();
  };


  const userId = localStorage.getItem("userId");
  const accessToken = Cookies.get('krizaar-user');

  if (!accessToken || !userId) {
    navigate("/signin");
  }

  useEffect(() => {
    if (!accessToken || !userId) {
      navigate("/signin");
      return;
    }

    if (state.checkoutItems.length === 0) {
      navigate("/");
      return;
    }

    const fetchUserDetails = async () => {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/auth/user/profile/detail`,
          { id: userId },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            }
          }
        );

        if (response.data && response.data.userdata) {
          setUserDetails({
            first_name: response.data.userdata.first_name,
            last_name: response.data.userdata.last_name,
            email: response.data.userdata.email,
            mobno: response.data.userdata.mobno,
            image: response.data.userdata.image ? response.data.userdata.image : null,
          })
        }
      } catch (e) {
        console.log(e, "Failed to fetch user details due to error: ", e.message)
      }
    }

    const fetchExistingAddresses = async () => {
      try {
        const response = await axios.post(`https://krizaar.com/api/auth/user/addresses`, {
          address: {
            user_id: userId
          }
        }, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });
        // console.log(response.data.userAddress)
        if (response.data.userAddress.length > 0) {
          setSelectedAddress(response.data.userAddress[0])
          setSelectedAddressOption('existing');
        }
        setExistingAddresses(response.data.userAddress);
      } catch (err) {
        console.log("Error in fetching address of the user");
      }
    };

    const fetchAllAvailableTaxes = async () => {
      try {
        const response = await axios.post(`https://krizaar.com/api/tax/getTaxes`);
        setAllAvailableTaxes(response.data.data)
      } catch (err) {
        console.log("Error in fetching all available taxes");
      }
    }
    fetchAllAvailableTaxes()
    fetchUserDetails();
    fetchExistingAddresses();
  }, [accessToken, navigate, state.checkoutItems, userId]);

  // console.log(selectedAddress)

  const addNewAddress = async () => {
    try {
      const response = await axios.post(`https://krizaar.com/api/auth/user/create/address`, {
        address: {
          user_id: userId,
          full_name: newAddress.name,
          pin_code: newAddress.zip,
          locality: newAddress.locality,
          address_detail: newAddress.address,
          country: 'United States',
          state: newAddress.state,
          city: newAddress.city,
          landmark: newAddress.landmark || '',
          alternate_phone_no: newAddress.alternatePhone || '',
          address_type: newAddress.addressType
        }
      }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });
      // console.log(response)
      setExistingAddresses([...existingAddresses, response.data.addressdata]);
      setSelectedAddress(response.data.addressdata);
    } catch (err) {
      console.log("Error in adding new address");
    }
  };

  const handleNextStep = async () => {
    if (currentStep === 2) {
      if (selectedAddressOption === 'new') {
        const requiredFields = ['name', 'phone', 'zip', 'locality', 'address', 'city', 'state', 'addressType'];
        const allFieldsFilled = requiredFields.every(field => newAddress[field].trim() !== '');

        if (!allFieldsFilled) {
          alert('Please fill out all required fields in the new address form.');
          return;
        }

        await addNewAddress();

        setSelectedAddressOption('existing');
        // setCurrentStep(prevStep => prevStep + 1);
      } else if (selectedAddress) {
        if (TAX_RATE === 0) {
          alert('Delivery is not possible at your location, please select another location')
          return;
        }
        setCurrentStep(prevStep => prevStep + 1);
      } else {
        alert('Please select an address to proceed.');
      }
    } else {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  useEffect(() => {
    if (selectedAddress) {
      const matchedEntry = allAvailableTaxes.find(
        (entry) =>
          entry.country === selectedAddress.country &&
          entry.state === selectedAddress.state
      );

      if (matchedEntry) {
        setTAX_RATE(matchedEntry.taxRate);
      } else {
        setTAX_RATE(0);
      }
    }
  }, [selectedAddress, allAvailableTaxes]);


  const handleAddressOptionChange = (event) => {
    if (!selectedAddress) {
      alert('There is not any existing address, Please add new address')
      return;
    }
    setSelectedAddressOption(event.target.value);
    // setSelectedAddress(null);
  };

  const handleNewAddressChange = (event) => {
    const { name, value } = event.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleIncrease = (id) => {
    dispatch({ type: 'INCREASE_QUANTITY', payload: { id } });
  };

  const handleDecrease = (id) => {
    dispatch({ type: 'DECREASE_QUANTITY', payload: { id } });
  };

  const handleRemoveItem = async (productId) => {
    dispatch({ type: 'REMOVE_CHECKOUT_ITEM', payload: { id: productId } });
  }

  useEffect(() => {
    const calculatedTotalPriceBeforeTax = state.totalPrice - state.totalDiscount;
    const calculatedTaxAmount = calculatedTotalPriceBeforeTax * (TAX_RATE / 100);
    const calculatedOrderTotal = calculatedTotalPriceBeforeTax + calculatedTaxAmount;

    setTotalPriceBeforeTax(calculatedTotalPriceBeforeTax);
    setTaxAmount(calculatedTaxAmount);
    setOrderTotal(calculatedOrderTotal);

  }, [state.totalPrice, state.totalDiscount, TAX_RATE]);

  const handlePlaceOrder = async () => {
    // const items = state.checkoutItems.map(item => ({
    //   productId: item.id,
    //   quantity: item.quantity.toString(),
    //   price: item.offer_price ? item.offer_price.toString() : item.price.toString(),
    // }));
    const items = state.checkoutItems.map(item => ({
      productId: item.id,
      image: item.image,
      measurements: item.measurements,
      name: item.name,
      price: item.offer_price !== null ? item.offer_price : item.price,
      productMaxStockQuantity: item.productMaxStockQuantity,
      quantity: item.quantity
    }));
    try {
      const response = await axios.post(`https://krizaar.com/api/product/placeOrder`, {
        userId,
        items,
        coupon: 'ABC200',
        offer: 'offer',
        paymentMethod: 'Cash',
        totalPrice: '200',
        status: 'pending',
        paymentStatus: 'pending',
        shippingAddress: selectedAddress._id
      });
      // console.log(response.data)
      alert('Order placed successfully');
      navigate('/allorders');
    } catch (error) {
      console.log(error.response?.data)
      alert('Failed to place order due to error: ' + error.message)
    }
  }

  const [stripeClientSecret, setstripeClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const handlePayment = async () => {

    if (!stripe || !elements) {
      return;
    }

    if(!grandTotal){
      alert('Please generate a random number to proceed')
      return;
    }

    try {
      const response = await fetch('https://krizaar.com/api/payment/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: parseInt(grandTotal * 100) }),
      });

      if (!response.ok) {
        throw new Error('Failed to create payment intent');
      }

      const finalResponse = await response.json();
      const clientSecret = finalResponse.clientSecret;
      // console.log(clientSecret)

      // Confirm payment
      const cardElement = elements.getElement(CardElement);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      // console.log(result)

      if (result.error) {
        console.error('[error]', result.error.message);
        alert(result.error.message)
      } else if (result.paymentIntent.status === 'succeeded') {
        // alert('Payment successful!');
        handlePlaceOrder()
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const generateRandomNumber = () => {
    const min = 1;
    const max = 15;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomNumber(randomNum);

    const discountAmount = (orderTotal * randomNum) / 100;
    const newTotalPrice = orderTotal - discountAmount;

    setRandomDiscountAmount(discountAmount)
    setGrandTotal(newTotalPrice);
    setRoundedGrandTotal(Math.ceil(newTotalPrice))
  }

  return (
    <>
      <Header />
      {isModalOpen && <div className="modal-backdrop fade show"></div>}
      <div className='checkout-section'>
        <div className='container'>
          <div className="checkout-section-inseide row m-0">
            <div className="checkout col-lg-8 col-md-8 col-sm-6 col-12">
              {/* Step 1: Confirm Your Login */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 1 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">1</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Confirm Your Login</h5></div>
                      {currentStep > 1 && (
                        <div className=' d-flex gap-3 mt-1'>
                          <span>{userDetails.first_name ? userDetails.first_name : ''} {userDetails.last_name ? userDetails.last_name : ''}</span>
                          <span>{userDetails.mobno}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 1 && <button className="checkout__btn" onClick={() => setCurrentStep(1)}>CHANGE</button>}
                </div>
                {currentStep === 1 && (
                  <div className="checkout__login-info">
                    <div className='checkout-user-info'>
                      <img className='img-fluid' src={userDetails.image ? `https://krizaar.com/api/uploads/${userDetails.image}` : '/user.png'} alt='user-image' />
                      <div className='checkout-user-details'>
                        <p className='m-1 checkout-user-name'>Hello, {userDetails.first_name ? userDetails.first_name : ''} {userDetails.last_name ? userDetails.last_name : ''}!</p>
                        <p className='m-1'>{userDetails.mobno}</p>
                      </div>
                    </div>
                    <div className='buttons d-flex gap-3'>
                      <button className="checkout__btn" onClick={openModal}>
                        Change
                      </button>
                      <button className="checkout__btn" onClick={handleNextStep}>
                        Next
                      </button>
                    </div>
                  </div>

                )}
                <div className={`modal fade ${isModalOpen ? 'show' : ''}`} style={{ display: isModalOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Confirm Logout</h5>
                        <button type="button" className="close-button" onClick={closeModal} aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <p>Do you want to log out and change the user?</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="checkout__btn checkout__save-btn" onClick={handleConfirmLogout}>Yes, Logout</button>
                        <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Step 2: Delivery Address */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 2 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">2</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Delivery Address</h5></div>
                      {currentStep > 2 && (
                        <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                          <div className="user-details d-flex gap-3">
                            <p className="m-0">{selectedAddress.full_name}</p>
                            <p className="m-0">{selectedAddress.alternate_phone_no}</p>
                          </div>
                          <div className="address">
                            {selectedAddress.address_detail}, {selectedAddress.locality}, {selectedAddress.city}, {selectedAddress.state}, {selectedAddress.zip}, {selectedAddress.country}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 2 && <button className="checkout__btn" onClick={() => setCurrentStep(2)}>CHANGE</button>}
                </div>
                {currentStep === 2 && (
                  <div className="checkout__address-section">
                    <div className="checkout__address-selection">
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="new"
                          checked={selectedAddressOption === 'new'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Add New Address</span>
                      </label>
                      <label className="custom-radio">
                        <input
                          type="radio"
                          name="addressOption"
                          value="existing"
                          checked={selectedAddressOption === 'existing'}
                          onChange={handleAddressOptionChange}
                        />
                        <span className="radio-button">Select Existing Address</span>
                      </label>
                    </div>
                    {selectedAddressOption === 'existing' ? (
                      <div className="checkout__existing-address">
                        {existingAddresses.length > 0 ? (
                          existingAddresses.map((address) => (
                            <label
                              key={address._id}
                              className={`checkout__address-item ${selectedAddress._id === address._id ? 'selected' : ''}`}
                              onClick={() => setSelectedAddress(address)}
                            >
                              <input
                                type="radio"
                                name="address"
                                value={address._id}
                                checked={selectedAddress._id === address._id}
                                onChange={() => setSelectedAddress(address)}
                              />
                              <div className="d-flex flex-column justify-content-center align-items-start gap-2">
                                <div className="user-details d-flex gap-3">
                                  <p className="m-0">{address.full_name}</p>
                                  <span>{address.address_type}</span>
                                  <p className="m-0">{address.alternate_phone_no}</p>
                                </div>
                                <div className="address">
                                  {address.address_detail}, {address.locality}, {address.city}, {address.state}, {address.zip}, {address.country}
                                </div>
                                {selectedAddress._id === address._id && (
                                  <button
                                    type="button"
                                    className="checkout__btn checkout__save-btn"
                                    onClick={handleNextStep}
                                  >
                                    Deliver to this Address
                                  </button>
                                )}
                              </div>
                            </label>
                          ))
                        ) : (
                          <p>No existing addresses found.</p>
                        )}
                      </div>
                    ) : (
                      <form className='new-address-form row m-0'>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Your Name</label>
                          <input type="text" name="name" placeholder="Enter your name" value={newAddress.name} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Phone Number</label>
                          <input type="tel" name="phone" placeholder="Enter your phone number" value={newAddress.phone} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Zip Code</label>
                          <input type="text" name="zip" placeholder="Enter your zip code" value={newAddress.zip} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Locality</label>
                          <input type="text" name="locality" placeholder="Enter your locality" value={newAddress.locality} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-12 col-md-12 col-sm-12 col-12">
                          <label>Address</label>
                          <input type="text" name="address" placeholder="Enter your address" value={newAddress.address} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Country</label>
                          <CountrySelect
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, country: e.name });
                              setCountryid(e.id);
                            }}
                            placeHolder="Select Country"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>State</label>
                          <StateSelect
                            countryid={countryid}
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, state: e.name });
                              setstateid(e.id);
                            }}
                            placeHolder="Select State"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>City</label>
                          <CitySelect
                            countryid={countryid}
                            stateid={stateid}
                            onChange={(e) => {
                              setNewAddress({ ...newAddress, city: e.name });
                            }}
                            placeHolder="Select City"
                          />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Landmark (Optional)</label>
                          <input type="text" name="landmark" placeholder="Enter a landmark" value={newAddress.landmark} onChange={handleNewAddressChange} />
                        </div>
                        <div className="checkout__form-group col-lg-6 col-md-6 col-sm-12 col-12">
                          <label>Alternate Phone (Optional)</label>
                          <input type="tel" name="alternatePhone" placeholder="Enter an alternate phone number" value={newAddress.alternatePhone} onChange={handleNewAddressChange} />
                        </div>

                        <div className="checkout__form-group col-12">
                          <label>Address Type</label>
                          <div className="checkout__radio-group">
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="home"
                                checked={newAddress.addressType === 'home'}
                                onChange={handleNewAddressChange}
                              />
                              Home (All day delivery)
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="addressType"
                                value="work"
                                checked={newAddress.addressType === 'work'}
                                onChange={handleNewAddressChange}
                              />
                              Office (delivery between 10AM to 5PM)
                            </label>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <button
                            type="button"
                            className="checkout__btn checkout__save-btn"
                            onClick={handleNextStep}
                          >
                            Save Delivery Address
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </div>

              {/* Step 3: Order Summary */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 3 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">3</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Order Summary</h5></div>
                      {currentStep > 3 && (
                        <div className=' d-flex gap-3 mt-1'>
                          {state.totalQuantity} items
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep > 3 && <button className="checkout__btn" onClick={() => setCurrentStep(3)}>CHANGE</button>}
                </div>
                {currentStep === 3 && (
                  <div className="checkout__order-summary">
                    {
                      state.checkoutItems.map((item, index) => (
                        <CheckoutProductItem
                          key={index}
                          product={item}
                          onRemove={handleRemoveItem}
                          onIncrease={handleIncrease}
                          onDecrease={handleDecrease}
                        />
                      ))
                    }
                    <div className=" text-center">
                      <button
                        type="button"
                        className="checkout__btn"
                        onClick={handleNextStep}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {/* Step 4: Payment Methods */}
              <div className="checkout__step">
                <div className={`checkout__step-header ${currentStep >= 4 ? 'checkout__completed' : ''}`}>
                  <div className=' d-flex gap-4'>
                    <div className="checkout__step-number">4</div>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Payment Options</h5></div>
                    </div>
                  </div>
                </div>
                {currentStep === 4 && (
                  <div className="checkout__payment-methods">
                    <div className="checkout__form-group">

                      <CardElement />
                    </div>
                    <button type="button" className="checkout__btn checkout__confirm-btn" onClick={handlePayment}>
                      Confirm
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="cart-summary col-lg-4 col-md-4 col-sm-6 col-12">
              <h3>PRICE DETAILS</h3>
              <div className="summary-detail">
                <span>Price ({state.totalQuantity} items) :</span>
                <span>$ {state.totalPrice}</span>
              </div>
              <div className="summary-detail">
                <span>Shipping & Handling:</span>
                <span className="free">Free</span>
              </div>
              <div className="summary-detail">
                <span>Promotion Applied: </span>
                <span className="discount">-$ {state.totalDiscount}</span>
              </div>
              <hr />
              <div className="summary-detail">
                <span>Cart Total: </span>
                <span>$ {totalPriceBeforeTax.toFixed(2)}</span>
              </div>
              {TAX_RATE !== 0 && <div className="summary-detail">
                <span>Estimated tax ({TAX_RATE}%)</span>
                <span>$ {taxAmount.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && <hr />}
              {TAX_RATE !== 0 && <div className="summary-detail">
                <span>Order Total</span>
                <span>$ {orderTotal.toFixed(2)}</span>
              </div>}
              {TAX_RATE !== 0 && randomNumber && <div className="summary-detail">
                <span>Random Discount Applied({randomNumber} %)</span>
                <span>{randomDiscountAmount.toFixed(2)}</span>
              </div>}
              {grandTotal && <hr />}
              {grandTotal && <div className="summary-detail">
                <span>Grand Total</span>
                <span>{roundToNearest ? roundedGrandTotal : grandTotal.toFixed(2)}</span>
              </div>}
              {grandTotal && <div className=" d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={roundToNearest}
                  onChange={(e) => setRoundToNearest(e.target.checked)}
                />
                <label className='m-0'>Round Off Grand Total</label>
              </div>}
              {TAX_RATE !== 0 && randomNumber === null && currentStep===4 && <div className=' text-center my-3'>
                <button
                  type="button"
                  className="checkout__btn"
                  onClick={generateRandomNumber}
                >
                  Generate Random Number
                </button>
              </div>}
              <div className="summary-detail savings mt-3">
                <span>You will save $ {(state.totalDiscount + randomDiscountAmount).toFixed(2)} on this order</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const CheckoutProductItem = ({ product, onRemove, onIncrease, onDecrease }) => {
  return (
    <div className="cart-item">
      <img src={product.isProductFromAliExpress ? product.product_banner_image : `https://krizaar.com/api/uploads/${product.product_banner_image}`} alt={product.name} className="product-image" />
      <div className="cart-product-details">
        <h4>{product.name}</h4>
        {product.seller && product.seller.first_name && (
          <span>
            Seller: {product.seller.first_name || ''} {product.seller.last_name || ''}
          </span>
        )}
        {product.offer_price ?
          <div className="product-price">
            <span className="cart-current-price">MRP:</span>
            <span className="cart-mrp">$ {product.price}</span>
            <span className="cart-current-price">$ {product.offer_price}</span>
            <span className="offers-applied">2 offers applied</span>
          </div> :
          <div className="product-price">
            <span className="cart-current-price">MRP: ${product.price}</span>
            <span className="offers-applied">2 offers applied</span>
          </div>
        }
        <div className="delivery-info d-flex gap-3">
          Delivery by Thu Jun 27 | <span className='text-decoration-line-through'>$ 40</span> <span className="free">Free</span>
        </div>
        <div className="product-actions">
          <div className="quantity-control">
            <button className="quantity-btn" onClick={() => onDecrease(product.id)}>-</button>
            <span className="quantity m-0">{product.quantity}</span>
            <button className="quantity-btn" onClick={() => onIncrease(product.id)}>+</button>
          </div>
          <button className="remove" onClick={() => onRemove(product.id)}>Remove</button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
