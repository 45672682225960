import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../header/Footer";
// import('../../components/WebPanel/WebPanelRoutes.css');

const Vsignup = () => {
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row mb-5">
            <div className="signin-box">
              <div className="signin-box1">
                <h4 className="text-center mt-5">Krizaar.com</h4>
                <div className="sign-form">
                  <h5 className="mb-4">Vendor SignUp</h5>
                  <form>
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="firstName">First Name</label>
                          <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="email-inputh"
                            placeholder=""
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="lastName">Last Name</label>
                          <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="email-inputh"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="contactEmail">Contact Email</label>
                          <input
                            type="email"
                            id="contactEmail"
                            name="contactEmail"
                            className="email-inputh"
                            placeholder=""
                            required
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="contactNumber">Contact Number</label>
                          <input
                            type="text"
                            id="contactNumber"
                            name="contactNumber"
                            className="email-inputh"
                            placeholder=""
                            required
                          />
                        </div>
                      </div>
                      <br />
                      <label htmlFor="businessName">Business Name</label>
                      <input
                        type="text"
                        id="businessName"
                        name="businessName"
                        className="email-input"
                        placeholder=""
                        required
                      />
                      <br />
                      <label htmlFor="businessAddress">Business Address</label>
                      <input
                        type="text"
                        id="businessAddress"
                        name="businessAddress"
                        className="email-input"
                        placeholder=""
                        required
                      />
                      <br />
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="email-input"
                        placeholder=""
                        required
                      />
                      <br />
                      <label htmlFor="businessType">Business Type</label>
                      <select
                        id="businessType"
                        name="businessType"
                        className="email-input"
                        required
                      >
                        <option value="">Select Business Type</option>
                        <option value="retail">Retail</option>
                        <option value="wholesale">Wholesale</option>
                        <option value="manufacturing">Manufacturing</option>
                        <option value="service">Service</option>
                      </select>
                      <br />
                      <br />
                      <div className="sbtn-box">
                        <button type="button" className="sub-btn">
                          SignUp
                        </button>
                      </div>
                    </>
                  </form>
                </div>
              </div>
            </div>

            <hr className="mt-4" />
            <div className="amazon-box">
              <span className="amazon">or</span>
            </div>
            <div className="create-acc-box">
              <NavLink to="/vendorsignup">
                <button className="create-acc">
                  If You have Signup. Plz Signin
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* {showPopup && <VendorPopup onClose={() => setShowPopup(false)} />} */}
    </>
  );
};

export default Vsignup;
