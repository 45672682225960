import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../AdminPanel/component/Dashboard';
import Header from "../AdminPanel/component/Header";
import Sidebar from "../AdminPanel/component/Sidebar";
import AllBrands from "../AdminPanel/component/brands/AllBrands"
import AddBrands from "../AdminPanel/component/brands/AddBrands";
import AddCategories from "../AdminPanel/component/categories/AddCategories";
import AddSubCategories from "../AdminPanel/component/sub-categories/AddSubCategories";
import AddSubSubCategories from "../AdminPanel/component/sub-sub-categories/AddSubSubCategories";
import Allproducts from "../AdminPanel/component/products/Allproducts";
import Addnewproduct from "../AdminPanel/component/products/Addnewproduct";
import AllAttributes from "../AdminPanel/component/AllAttributes";
import AllOrders from "../AdminPanel/component/orders/AllOrders";
import OrderDetails from "../AdminPanel/component/orders/orderDetails";
import PendingOrders from "../AdminPanel/component/orders/pendingOrders";
import CancelOrders from "../AdminPanel/component/orders/cancelOrders";
import ConfirmedOrders from "../AdminPanel/component/orders/confirmedOrder";
import AddBanners from "../AdminPanel/component/banners/addBanners";
import AllVendors from "../AdminPanel/component/vendors/allVendors";
import VendorDetails from "../AdminPanel/component/vendors/vendorDetails";
import AllUsers from "../AdminPanel/component/users/allUsers";
import UserrDetails from "../AdminPanel/component/users/userDetails";
import ReturnOrders from "../AdminPanel/component/orders/returnOrder";
import ReturnDetails from "../AdminPanel/component/orders/returnDetails";
import Shipping from "../AdminPanel/component/orders/shipping";
import OffersList from "../AdminPanel/component/offers/offers";
import ProfileAdmin from "../AdminPanel/component/ProfileAdmin";
import ChangePassword from "../AdminPanel/component/ChangePassword";
import ProductTaxes from "../AdminPanel/component/ProductTaxes";
import ImportProducts from "../AdminPanel/component/aliExpress/ImportProducts";
import Settings from "../AdminPanel/component/settings/Settings";

const AdminPanelRoutes = () => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };
    return (
        <>
            <div className="app admin-panel-body">
                <Header collapsed={collapsed} onToggle={toggleSidebar} />
                <div className="main-container">
                    <Sidebar
                        collapsed={collapsed}
                    />
                    <div className="main-content">
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/allbrands" element={<AllBrands />} />
                            <Route path="/addbrand" element={<AddBrands />} />
                            <Route path="/categories" element={<AddCategories />} />
                            <Route path="/subcategories" element={<AddSubCategories />} />
                            <Route path="/subsubcategories" element={<AddSubSubCategories />} />
                            <Route path="/allproducts" element={<Allproducts />} />
                            <Route path="/addproduct" element={<Addnewproduct editType={'Add'} />} />
                            <Route path="/editproduct" element={<Addnewproduct editType={'Edit'} />} />
                            <Route path="/attributes" element={<AllAttributes />} />
                            <Route path="/allOrders" element={<AllOrders />} />
                            <Route path="/OrderDetails" element={<OrderDetails />} />
                            <Route path="/returnOrders" element={<ReturnOrders />} />
                            <Route path="/returnDetails" element={<ReturnDetails />} />
                            <Route path="/shipping" element={<Shipping />} />
                            <Route path="/pendingOrders" element={<PendingOrders />} />
                            <Route path="/cancelOrders" element={<CancelOrders />} />
                            <Route path="/completedOreders" element={<ConfirmedOrders />} />
                            <Route path="/addBanner" element={<AddBanners />} />
                            <Route path="/allvendors" element={<AllVendors />} />
                            <Route path="/vendorDetails" element={<VendorDetails />} />
                            <Route path="/allusers" element={<AllUsers />} />
                            <Route path="/userDetails" element={<UserrDetails />} />
                            <Route path="/offers" element={<OffersList/>} />
                            <Route path="/profile" element={<ProfileAdmin/>} />
                            <Route path="/productTaxes" element={<ProductTaxes/>} />
                            <Route path="/changePassword" element={<ChangePassword/>} />
                            <Route path="/import_product" element={<ImportProducts/>} />
                            <Route path="/settings" element={<Settings/>} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminPanelRoutes;
