import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
  useEffect(()=>{
    //import('./WebPanelRoutes.css');
  },[])
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="my-5">
              <p>
                Welcome to Krizaar! Your privacy is important to us. This
                Privacy Policy outlines how we collect, use, and protect your
                personal information when you visit or make a purchase from our
                website.
              </p>
              <h4>Information We Collect:</h4>
              <p>
                When you visit Krizaar, we automatically collect certain
                information about your device, including your web browser, IP
                address, and time zone. Additionally, as you browse the site, we
                collect information about the individual web pages or products
                that you view and information about how you interact with the
                site. We also collect personal information that you provide to
                us, such as your name, billing address, shipping address,
                payment information, email address, and phone number.
              </p>
              <h4>How We Use Your Information:</h4>
              <p>
                We use the information we collect to fulfill orders placed
                through Krizaar, including processing your payment information,
                arranging for shipping, and providing you with invoices and/or
                order confirmations. Additionally, we use this information to
                communicate with you, screen our orders for potential risk or
                fraud, and, with your consent, provide you with information or
                advertising relating to our products or services.
              </p>
              <h4>Sharing Your Information:</h4>
              <p>
                We share your personal information with third parties to help us
                use your information as described above. For example, we use
                payment processors to securely process payments and shipping
                companies to deliver your orders. We will never sell your
                personal information to third parties.
              </p>
              <h4>Data Security:</h4>
              <p>
                We take the security of your personal information seriously and
                implement appropriate measures to protect it from unauthorized
                access, alteration, disclosure, or destruction.
              </p>
              <h4>Changes to Our Privacy Policy:</h4>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons.
              </p>
              <p>
                For any questions or concerns regarding our privacy practices,
                please contact us at
                <NavLink to="mailto:info@krizaar.com">info@krizaar.com</NavLink>
              </p>
              <p>
                Thank you for trusting Krizaar with your personal information.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
