import React from 'react'
import CommonOtherLinkComponent from './krizaar_pay/CommonOtherLinkComponent'

const KrizaarPay = () => {
  const contentArray = [
    {
      id: 1,
      title: 'Trust and Innovation in Every Transaction',
      subtitle: 'The Ultimate Digital Payment Experience',
      content: 'Experience the power of blockchain technology with unparalleled security, transparency, and efficiency. Krizaar Pay is designed for users who demand the best in digital payments.',
      image: '/images/sec2A.jpeg'
    },
    {
      id: 2,
      title: 'Your Money, Our Priority',
      subtitle: 'Advanced Blockchain Security Measures',
      content: 'With Krizaar Pay, your transactions are protected by state-of-the-art encryption and decentralized verification, ensuring your funds are always safe from fraud and hacking.',
      image: '/images/sec2B.jpeg'
    },
    {
      id: 3,
      title: 'Speed that Matters',
      subtitle: 'Instant Payments, Anytime, Anywhere',
      content: 'Say goodbye to waiting times. Krizaar Pay leverages blockchain technology to provide instant transaction processing, no matter where you are in the world.',
      image: '/images/sec2C.png'
    },
    {
      id: 4,
      title: 'Simple, Intuitive, Powerful',
      subtitle: 'Designed with You in Mind',
      content: 'Our platform is designed to be user-friendly and intuitive, making it easy for anyone to send and receive money with just a few clicks.',
      image: '/images/sec2D.png'
    },
    {
      id: 5,
      title: 'Payments Without Borders',
      subtitle: 'Worldwide Reach, Local Feel',
      content: 'Krizaar Pay brings the world closer together by enabling seamless cross-border transactions. Enjoy global accessibility with the security of local banking.',
      image: '/images/sec2E.jpeg'
    }
  ]

  const heroSectionItems = {
    title: 'KRIZAAR PAY',
    subtitle: 'Revolutionizing Digital Payments',
    content: 'Seamless, Secure, and Swift Transactions on the Blockchain',
    content2: 'Discover the future of online transactions with Krizaar Pay, a blockchain-based payment solution that ensures your money is always safe, fast, and accessible. Join us in transforming how the world moves money.',
    image: '/images/hero.jpeg'
  }
  return (
    <>
      <CommonOtherLinkComponent title="Why Choose Krizaar Pay?" contentArray={contentArray} heroSectionItems={heroSectionItems} />
    </>
  )
}

export default KrizaarPay
