import React, { useState } from 'react'
import profileIcon from '../img/woman.png'

const ChangePassword = () => {
    return (
        <>
            <section className="custom-cart-panel mb-3">
                <div className="container-fuild">
                    <div className="row">
                        <div className="vendor-top">
                            <h5 className="title-v">Change Password</h5>
                        </div>
                    </div>
                </div>
                <div className="card-header border-top px-0 pt-4 py-0">
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className="mb-2">
                                <label for="exampleFormControlInput1" className="form-label">Current Password*</label>
                                <input type="text" className="form-control" id="categoryNameId" placeholder="current password" />
                            </div>
                            <div className="mb-2">
                                <label for="exampleFormControlInput1" className="form-label">New Password*</label>
                                <input type="text" className="form-control" id="categoryNameId" placeholder="new password" />
                            </div>
                            <div className="mb-4">
                                <label for="exampleFormControlInput1" className="form-label">Confirm Password*</label>
                                <input type="text" className="form-control" id="categoryNameId" placeholder="confirm password" />
                            </div>
                            <button className="trash-icon px-3 py-2" >Change Password</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChangePassword;