import React, { useEffect } from "react";
import Header from "../../componenth/header/Header";
import Footer from "../../componenth/header/Footer";
import { NavLink } from "react-router-dom";

const TermsCondition = () => {
  useEffect(()=>{
    //import('./WebPanelRoutes.css');
  },[])
  return (
    <>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="my-5">
              <p>
                Welcome to Krizaar! By accessing or using our website, you agree
                to comply with and be bound by the following terms and
                conditions. Please read them carefully.
              </p>
              <h4>General Terms:</h4>
              <p>
                By using Krizaar, you affirm that you are at least 18 years old
                or have parental consent to use our site. You agree to use the
                site for lawful purposes only and in a manner that does not
                infringe the rights of, or restrict or inhibit the use and
                enjoyment of the site by, any third party.
              </p>
              <h4>Account and Registration:</h4>
              <p>
                To access certain features of our site, you may be required to
                create an account. You must provide accurate and complete
                information and keep your account information updated. You are
                responsible for maintaining the confidentiality of your account
                credentials and for all activities that occur under your
                account.
              </p>
              <h4>Product Descriptions and Pricing:</h4>
              <p>
                We strive to provide accurate product descriptions and pricing
                information. However, we do not warrant that product
                descriptions or other content on the site are accurate,
                complete, reliable, current, or error-free. In the event of any
                errors, we reserve the right to correct them and cancel any
                affected orders.
              </p>
              <h4>Order Acceptance:</h4>
              <p>
                Your order constitutes an offer to purchase products. We reserve
                the right to accept or decline your order for any reason,
                including limitations on quantities available for purchase,
                inaccuracies in product or pricing information, or problems
                identified by our credit and fraud prevention department.
              </p>
              <h4>Intellectual Property:</h4>
              <p>
                All content on Krizaar, including text, graphics, logos, images,
                and software, is the property of Krizaar or its content
                suppliers and is protected by intellectual property laws. You
                may not use, reproduce, or distribute any content without our
                prior written permission.
              </p>
              <h4>Limitation of Liability:</h4>
              <p>
                Krizaar shall not be liable for any indirect, incidental,
                special, or consequential damages resulting from the use or
                inability to use our site or products, even if we have been
                advised of the possibility of such damages.
              </p>
              <h4>Changes to Terms:</h4>
              <p>
                We reserve the right to update or modify these terms and
                conditions at any time without prior notice. Your continued use
                of the site after any changes indicates your acceptance of the
                new terms.
              </p>
              <h4>Contact Us:</h4>
              <p>
                If you have any questions about these Terms and Conditions,
                please contact us at{" "}
                <NavLink to="mailto:info@krizaar.com">info@krizaar.com</NavLink>
              </p>
              <p>
                Thank you for choosing Krizaar. We hope you enjoy your shopping
                experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default TermsCondition;
