import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import {
  _existProductData,
  _feedNameURL,
  _fetchCategoryList,
  _fetchProductData,
  getProductByCategoryURL,
  getSettingDataURL,
  updateProductByCategoryURL,
} from "../../../../url";
import { ApiCall, getProducts } from "../../services";
import ProductCard from "./ProductCard";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress } from "@mui/material";

// ** credentials

function ImportProducts() {
  const [categoryForAli, setCatForAli] = useState([]);
  const [categoryForKrizaar, setCatForKrizaar] = useState([]);
  const [catFirst, setcatFirst] = React.useState("");
  const [cateSecond, setcateSecond] = React.useState(null);
  const [cateLast, setcateLast] = React.useState(null);
  const [credentials, setcredentials] = React.useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = React.useState([]);
  const [secondLevelCat, setSecondLevelCal] = useState([]);
  const [existingProduct, setExistingProduct] = useState([]);
  

  const [secondLevelCatSelected, setSecondLevelCatSelected] = useState(false);

  /**
   * fetch existing products
   */

  useEffect(() => {
    getProducts(_existProductData, {})
    .then((res) => {
      const products = res.data.productData.map(
        (product) => +product?.aliEx_product_id
      );
      console.log("_existProductData", products);
      setExistingProduct(products);
    });
  }, []);

  //  fetch settings
  useEffect(() => {
    async function fetchCredentails() {
      try {
        let res = await axios.get(getSettingDataURL);
        const result = res?.data;
        if (result?.success) {
          const { app_key, redirect_url, appSecret, code, access_token } =
            result.data;
          setcredentials({
            app_key: app_key,
            redirect_url: redirect_url,
            appSecret: appSecret,
            code: code,
            access_token: access_token,
          });
        }
      } catch (error) {
        console.error("error", error);
      }
    }
    fetchCredentails();
  }, []);


  // fetch feed name
  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController instance
    const signal = controller.signal;
  
    async function fetchCategoryForAliEx() {
      try {
        let res = await axios.get(_feedNameURL, { signal }); // Attach the signal to the axios request
        console.log("response", res);
  
        const error_response = res.data.error_response;
  
        if (error_response) {
          toast.error("Access Token Expired", {
            position: "bottom-right",
          });
          return;
        }
  
        let promos =
          res?.data?.aliexpress_ds_feedname_get_response?.resp_result?.result
            ?.promos || [];
        setCatForAli(promos.promo);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("error", error);
        }
      }
    }
  
    fetchCategoryForAliEx();
  
    // Cleanup function to cancel the request if the component unmounts
    return () => {
      controller.abort(); // Cancels the request
    };
  }, []);

  /**
   * @fetch categorys for both ali express and krizzar
   * */
  useEffect(() => {
    async function fetchCategoryForKrizaar() {
      try {
        let res = await axios.get(_fetchCategoryList);
        console.log(res.data.data);
        // let promos = res?.data?.aliexpress_ds_feedname_get_response?.resp_result?.result?.promos || [ ];
        // setCatForKrizaar(promos.promo);
        setCatForKrizaar(res?.data?.data || []);
      } catch (error) {
        console.error("error", error);
      }
    }

    // fetch category for ali expresss
    fetchCategoryForKrizaar();
  }, []);

  /** handle change select */
  const handleChange = async (event) => {
    setcatFirst(event.target.value);
    const feedName = event.target.value;

    if (!credentials) {
      alert("somthing went wrong");
      return;
    }

    // payload
    const payload = {
      ...credentials,
      feedName: feedName,
    };

    // console.log("payload",payload)
    ApiCall(updateProductByCategoryURL, payload)
      .then((res) => {
        const result = res.data;
        if (result?.success) {
          return true;
        }
      })
      .then(() => {
        return getProducts(_fetchProductData, {});
      })
      .then((res) => {
        const products =
          res.data?.aliexpress_ds_recommend_feed_get_response?.result?.products
            ?.traffic_product_d_t_o || [];
        // console.log("product Data", products);

        // // nonExistingProducts
        // const nonExistingProducts = products.filter(product => {
        //    if(!existingProduct.includes(product?.product_id)) {
        //      return product;
        //    }
        // });

        const secondLevelCaegoroys = products?.map(
          (product) => product?.second_level_category_name
        );
        const uniqueArray = [...new Set(secondLevelCaegoroys)];

        setSecondLevelCal(uniqueArray);
        setProducts(products);
        // setLoading(false);
      })
      .catch((err) => {
        console.error("handleChange**", err);
      });
  };

  /** handle change select */
  const handleChange2 = (event) => {
    setcateSecond(event.target.value);
    setSecondLevelCatSelected(true);
    setLoading(true);
    getProducts(_fetchProductData, {}).then((res) => {
      let products =
        res.data?.aliexpress_ds_recommend_feed_get_response?.result?.products
          ?.traffic_product_d_t_o || [];

      console.log("existingProduct", existingProduct);
      // filter non  existing products
      const nonExistingProducts = products
        .filter((product) => {
          if (!existingProduct.includes(product?.product_id)) {
            return product;
          }
        })
        .filter(
          (product) =>
            product?.second_level_category_name === event.target.value
        );

      // get product by second level cat
      // products = nonExistingProducts?.filter(product => product?.second_level_category_name === event.target.value);
      setProducts(nonExistingProducts);
      setLoading(false);
    });
    setError(false);
  };

  const handleChange3 = (e) => {
    setcateLast(e.target.value);
  };

  console.log("products", products);

  return (
    <>
      <section className="custom-cart-panel mb-3">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <h5 className="title-v">Add Product</h5>
              <div className="button-right-side">
                <div className="addBrands">{/* <BrandPopup /> */}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel mb-3 position-sticky">
        <div className="container-fluid">
          <div className="row">
            <div className="vendor-top">
              <Box className="d-flex align-items-center gap-2 w-100">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Product
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={catFirst}
                    label="Age"
                    onChange={handleChange}
                  >
                    {categoryForAli?.map(({ promo_name }) => (
                      <MenuItem key={promo_name} value={promo_name}>
                        {promo_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span className="">(Ali Express)</span>
              </Box>
            </div>
            <div className="vendor-top flex-column gap-3 flex-md-row">
              <Box className="d-flex align-items-center gap-2 w-100 w-md-50">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Sub Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cateSecond}
                    label="Age"
                    onChange={handleChange2}
                    // style={{outline : error ? '2px solid red' : null}}
                    onClick={() => {
                      if (secondLevelCat.length === 0) {
                        toast.error("Please Select Product");
                      }
                    }}
                  >
                    {secondLevelCat?.map((catName, idx) => (
                      <MenuItem key={`catName-${idx}`} value={catName}>
                        {catName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span>(Ali Express)</span>
              </Box>
              <Box className="d-flex align-items-center gap-2 w-100 w-md-50">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={cateLast}
                    label="Age"
                    onChange={handleChange3}
                    style={{ outline: error ? "2px solid red" : null }}
                  >
                    {categoryForKrizaar?.map(
                      ({ _id, category_name, level }) => (
                        <MenuItem
                          key={_id}
                          value={`${category_name}#${level}#${_id}`}
                        >
                          {category_name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
                <span>(Krizaar)</span>
              </Box>
            </div>
          </div>
        </div>
      </section>
      <section className="custom-cart-panel mb-3">
        <div className="p-2">
          {cateSecond && <h3>Products of {cateSecond}</h3>}
        </div>

        {loading && (
          <Box sx={{ display: "flex" }} className="justify-content-center">
            <CircularProgress />
          </Box>
        )}

        {!loading &&
          cateSecond &&
          products &&
          products?.map((product) => (
            <ProductCard
              key={product?.product_id}
              product={product}
              cateSecond={cateLast}
              setError={setError}
              existingProduct={existingProduct}
              setProducts={setProducts}
            />
          ))}
      </section>
      <Toaster />
    </>
  );
}

export default ImportProducts;
