import React, { useState } from "react";
import Icon1 from "../icon/organization.png";
import { NavLink } from "react-router-dom";

const Sidebar = ({ collapsed }) => {
  const [activeTab, setActiveTab] = useState("allcustomer");
  const [dropdowns, setDropdowns] = useState({
    customers: false,
    vendors: false,
    products: false,
    brands: false,
    categories: false,
    orders: false,
    users: false,
    offers:false,
    aliExpress:false
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleDropdownClick = (dropdownName) => {
    setDropdowns((prevState) => ({
      customers: dropdownName === "customers" ? !prevState.customers : false,
      vendors: dropdownName === "vendors" ? !prevState.vendors : false,
      products: dropdownName === "products" ? !prevState.products : false,
      brands: dropdownName === "brands" ? !prevState.brands : false,
      categories: dropdownName === "categories" ? !prevState.categories : false,
      orders: dropdownName === 'orders' ? !prevState.orders : false,
      users: dropdownName === 'users' ? !prevState.users : false,
      offers: dropdownName === 'offers' ? !prevState.offers : false,
      aliExpress: dropdownName === 'aliExpress' ? !prevState.aliExpress : false,
    }));
  };

  return (
    <div className={`sidebar ${collapsed ? "collapsed" : ""}`}>
      <ul className="nav nav-drop">
        {!collapsed && (
          <>
            <div className="nav-section">
              <NavLink to="/admin/dashboard" className="sidebarList" style={{ color: "#fff", textDecoration: 'none' }} activeClassName="active">
                <i class="fa fa-tachometer" aria-hidden="true"></i> <span className="slidebarspan">Dashboard</span>
              </NavLink>
            </div>
            <div className="nav-section">
              <NavLink to="/admin/allbrands" className="sidebarList" style={{ color: "#fff", textDecoration: 'none' }} activeClassName="active">
                <i class="fa fa-bookmark" aria-hidden="true"></i> <span className="slidebarspan">Brands</span>
              </NavLink>
            </div>
            <div className="nav-section">
              <NavLink to="/admin/addBanner" className="sidebarList" style={{ color: "#fff", textDecoration: 'none' }} activeClassName="active">
                <i class="fa fa-ticket" aria-hidden="true"></i> <span className="slidebarspan">Banners</span>
              </NavLink>
            </div>
            {/* <div className={`nav-section ${dropdowns.brands ? "sideWrap" : ""}`}>
              
                <a
                  className="btn drop-menu sidebarList"
                  data-bs-toggle="collapse"
                  href="#collapsebrands"
                  role="button"
                  aria-expanded={dropdowns.brands ? "true" : "false"}
                  aria-controls="collapsebrands"
                  onClick={() => handleDropdownClick("brands")}
                >
                  <i className="fas fa-bookmark me-2"></i>
                  <span className="slidebarspan">Brands</span>
                  <i
                    className={`fas fa-angle-${dropdowns.brands ? "down" : "right"
                      }`}
                  ></i>
                </a>
              
              <div
                className={`collapse ${dropdowns.brands ? "show" : ""}`}
                id="collapsebrands"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink className={activeTab === "allbrands" ? "active" : ""} to="allbrands" >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All brands</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addBrands" ? "active" : ""}
                    to="addbrand"
                    onClick={() => handleTabClick("addBrands")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Add Brands</span>
                  </NavLink>
                </div>
              </div>
            </div> */}

            <div className={`nav-section ${dropdowns.categories ? "sideWrap" : ""}`}>

              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapsecategories"
                role="button"
                aria-expanded={dropdowns.categories ? "true" : "false"}
                aria-controls="collapsecategories"
                onClick={() => handleDropdownClick("categories")}
              >
                <i className="fas fa-list-alt me-2"></i>
                <span className="slidebarspan">Categories</span>
                <i
                  className={`fas fa-angle-${dropdowns.categories ? "down" : "right"
                    }`}
                ></i>
              </a>

              <div
                className={`collapse ${dropdowns.categories ? "show" : ""}`}
                id="collapsecategories"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    className={activeTab === "categories" ? "active" : ""}
                    to="categories" >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Category</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addcategories" ? "active" : ""}
                    to="subcategories"
                    onClick={() => handleTabClick("addcategories")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Sub Category</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addcategories" ? "active" : ""}
                    to="subsubcategories"
                    onClick={() => handleTabClick("addcategories")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Child Category</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className={`nav-section ${dropdowns.orders ? "sideWrap" : ""}`}>

              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseOrders"
                role="button"
                aria-expanded={dropdowns.orders ? "true" : "false"}
                aria-controls="collapseOrders"
                onClick={() => handleDropdownClick("orders")}
              >
                <i className="fas fa-list me-2"></i>
                <span className="slidebarspan">Orders</span>
                <i
                  className={`fas fa-angle-${dropdowns.orders ? "down" : "right"
                    }`}
                ></i>
              </a>

              <div
                className={`collapse ${dropdowns.orders ? "show" : ""}`}
                id="collapseOrders"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    className={activeTab === "Orders" ? "active" : ""}
                    to="allOrders" >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Orders</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addOrders" ? "active" : ""}
                    to="pendingOrders"
                    // onClick={() => handleTabClick("addOrders")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Pending Orders </span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addOrders" ? "active" : ""}
                    to="cancelOrders"
                    // onClick={() => handleTabClick("addOrders")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Cancel Orders</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addOrders" ? "active" : ""}
                    to="completedOreders"
                    // onClick={() => handleTabClick("addOrders")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Completed Orders</span>
                  </NavLink>
                  <NavLink
                    className={activeTab === "addOrders" ? "active" : ""}
                    to="returnOrders"
                    // onClick={() => handleTabClick("addOrders")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Return Orders</span>
                  </NavLink>
                  
                </div>
              </div>
            </div>

            <div className={`nav-section ${dropdowns.products ? "sideWrap" : ""}`}>

              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseProducts"
                role="button"
                aria-expanded={dropdowns.products ? "true" : "false"}
                aria-controls="collapseProducts"
                onClick={() => handleDropdownClick("products")}
              >
                <i className="fas fa-store me-2"></i>
                <span className="slidebarspan">Products</span>
                <i
                  className={`fas fa-angle-${dropdowns.products ? "down" : "right"
                    }`}
                ></i>
              </a>

              <div
                className={`collapse ${dropdowns.products ? "show" : ""}`}
                id="collapseProducts"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    to={'allproducts'}
                    className={activeTab === "allproducts" ? "active" : ""}
                    onClick={() => handleTabClick("allproducts")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Products</span>
                  </NavLink>
                  <NavLink
                    to={'addproduct'}
                    className={activeTab === "addnewproduct" ? "active" : ""}
                    onClick={() => handleTabClick("addnewproduct")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Add New Product</span>
                  </NavLink>
                  <NavLink
                    to={'attributes'}
                    className={activeTab === "attributes" ? "active" : ""}
                    onClick={() => handleTabClick("attributes")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Product Attributes</span>
                  </NavLink>
                  <NavLink
                    to={'productTaxes'}
                    className={activeTab === "productTaxes" ? "active" : ""}
                    onClick={() => handleTabClick("productTaxes")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Product Tax</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className={`nav-section ${dropdowns.customers ? "sideWrap" : ""}`}>

              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseCustomers"
                role="button"
                aria-expanded={dropdowns.customers ? "true" : "false"}
                aria-controls="collapseCustomers"
                onClick={() => handleDropdownClick("customers")}
              >
                <i className="fas fa-users me-2"></i>
                <span className="slidebarspan">Customers</span>
                <i
                  className={`fas fa-angle-${dropdowns.customers ? "down" : "right"
                    }`}
                ></i>
              </a>

              <div
                className={`collapse ${dropdowns.customers ? "show" : ""}`}
                id="collapseCustomers"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                  to={'allusers'}
                    className={activeTab === "allcustomer" ? "active" : ""}
                    onClick={() => handleTabClick("allcustomer")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">All Customers</span>
                  </NavLink>
                  {/* <NavLink
                    className={
                      activeTab === "cancellationrequest" ? "active" : ""
                    }
                    onClick={() => handleTabClick("cancellationrequest")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Cancellation Request</span>
                  </NavLink> */}
                </div>
              </div>
            </div>
            <div className={`nav-section ${dropdowns.vendors ? "sideWrap" : ""}`}>
              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseVendors"
                role="button"
                aria-expanded={dropdowns.vendors ? "true" : "false"}
                aria-controls="collapseVendors"
                onClick={() => handleDropdownClick("vendors")}
              >
                <i className="fas fa-users me-2"></i>
                <span className="slidebarspan">Vendors</span>
                <i
                  className={`fas fa-angle-${dropdowns.vendors ? "down" : "right"
                    }`}
                ></i>
              </a>

              <div
                className={`collapse ${dropdowns.vendors ? "show" : ""}`}
                id="collapseVendors"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    to={'allvendors'}
                    className={activeTab === "vendors" ? "active" : ""}
                    onClick={() => handleTabClick("vendors")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Vendors</span>
                  </NavLink>
                </div>
              </div>
            </div>
            {/* <div className={`nav-section ${dropdowns.users ? "sideWrap" : ""}`}>
              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseUsers"
                role="button"
                aria-expanded={dropdowns.users ? "true" : "false"}
                aria-controls="collapseUsers"
                onClick={() => handleDropdownClick("users")}
              >
                <i className="fas fa-users me-2"></i>
                <span className="slidebarspan">Users</span>
                <i
                  className={`fas fa-angle-${dropdowns.users ? "down" : "right"
                    }`}
                ></i>
              </a>
              <div
                className={`collapse ${dropdowns.users ? "show" : ""}`}
                id="collapseUsers"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    to={'allusers'}
                    className={activeTab === "users" ? "active" : ""}
                    onClick={() => handleTabClick("users")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Users</span>
                  </NavLink>
                </div>
              </div>
            </div> */}
            <div className={`nav-section ${dropdowns.offers ? "sideWrap" : ""}`}>
              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseOffers"
                role="button"
                aria-expanded={dropdowns.offers ? "true" : "false"}
                aria-controls="collapseOffers"
                onClick={() => handleDropdownClick("offers")}
              >
                <i className="fas fa-users me-2"></i>
                <span className="slidebarspan">Offers</span>
                <i
                  className={`fas fa-angle-${dropdowns.offers ? "down" : "right"
                    }`}
                ></i>
              </a>
              <div
                className={`collapse ${dropdowns.offers ? "show" : ""}`}
                id="collapseOffers"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    to={'offers'}
                    className={activeTab === "offers" ? "active" : ""}
                    onClick={() => handleTabClick("offers")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Offers List</span>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className={`nav-section ${dropdowns.aliExpress ? "sideWrap" : ""}`}>
              <a
                className="btn drop-menu sidebarList"
                data-bs-toggle="collapse"
                href="#collapseAliExpress"
                role="button"
                aria-expanded={dropdowns.aliExpress ? "true" : "false"}
                aria-controls="collapseAliExpress"
                onClick={() => handleDropdownClick("aliExpress")}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-truck"><path d="M14 18V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a1 1 0 0 0 1 1h2"/><path d="M15 18H9"/><path d="M19 18h2a1 1 0 0 0 1-1v-3.65a1 1 0 0 0-.22-.624l-3.48-4.35A1 1 0 0 0 17.52 8H14"/><circle cx="17" cy="18" r="2"/><circle cx="7" cy="18" r="2"/></svg>{" "}
                <span className="slidebarspan">AliExpress Product</span>
                <i
                  className={`fas fa-angle-${dropdowns.aliExpress ? "down" : "right"
                    }`}
                ></i>
              </a>
              <div
                className={`collapse ${dropdowns.aliExpress ? "show" : ""}`}
                id="collapseAliExpress"
              >
                <div className="card side-navbar-dropdown">
                  <NavLink
                    to={'settings'}
                    className={activeTab === "aliExpress" ? "active" : ""}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Generate Code</span>
                  </NavLink>
                  <NavLink
                    to={'import_product'}
                    className={activeTab === "import_product" ? "active" : ""}
                    onClick={() => handleTabClick("import_product")}
                  >
                    <i className="fa fa-circle fa-2xs"></i>{" "}
                    <span className="org-tit">Import Products</span>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
