import React, { useContext, useEffect, useState } from "react";
import cart from "../img/icon/cart-outline.png";
import whishlist from "../img/icon/heart.png";
import exchange from "../img/icon/exchange.png";
import arrow from "../img/icon/Arrow.png";
import Cookies from 'js-cookie';

// import CartLogo from "../../assests/icon/cart-outline.png";
// import WishlistLogo from "../../assests/icon/Vector (2).png";
// import ExchangeLogo from "../../assests/icon/_exchange.png";
// import RightArrow from "../../assests/illustrations/Arrow.png";
import "../index.css";
import { CartContext } from "../components/WebPanel/cart/CartContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ProductCard = ({ product }) => {
  const [subImageIndex, setSubImageIndex] = useState(0);

  const handleNextSubImage = () => {
    if (product.product_gallery_image && product.product_gallery_image.length > 0) {
      setSubImageIndex(
        (prevIndex) => (prevIndex + 1) % product.product_gallery_image.length
      );
    }
  };

  let visibleSubImages = [];
  if (product.product_color_image.length > 0) {
    product.product_color_image.forEach(colorImage => {
      visibleSubImages = [...visibleSubImages, ...colorImage.gallery];
      if (colorImage.gallery.length === 0) {
        visibleSubImages.push(colorImage.image)
      }
    });
  } else {
    visibleSubImages = [...visibleSubImages, ...product.product_gallery_image];
  }

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set())

  const { state, dispatch } = useContext(CartContext);


  const onSingleProduct = () => {
    navigate(`/singleproduct/${product._id}`);
  };

  const handleAddToCart = async () => {

    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      alert("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      alert("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          alert("Product added to the cart!");
        } else {
          alert("Failed to add product to the cart");
        }
      } catch (error) {
        console.error("Error adding product to the cart:", error);
        alert("An error occurred. Please try again.");
      }
    }
  }

  const handleAddToWishlist = async () => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      alert("You need to be logged in to add products to your wishlist.");
      navigate("/signin");
      return;
    }

    if (wishlist.has(product._id)) {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [product._id],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          alert("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(product._id);
            return new Set(newWishlist);
          });
          return;
        } else {
          alert("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        console.error("Error removing product from wishlist:", error?.response?.data?.message);
        alert("An error occurred. Please try again.");
        return;
      }
    }

    console.log(userId)
    console.log(product._id)
    console.log(wishlist)

    try {
      const response = await axios.post(
        `https://krizaar.com/api/product/wishlist/add`,
        {
          userId: userId,
          productId: product._id,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        alert("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(product._id));
      } else {
        alert("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error?.response?.data?.massage);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `https://krizaar.com/api/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        // console.log("Wishlist Items:", wishlist)
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlist();
  }, [])

  return (
    <div className="product-card" onClick={onSingleProduct}>
      <img src={product?.isProductFromAliExpress ? product.product_banner_image : `https://krizaar.com/api/uploads/${product.product_banner_image}`} alt={product.product_name} className="product-image" />
      <div className="product-detail">
        <div className="card-top">
          {/* <span className="timer">{product.timer}</span> */}
          {/* <span className="discount-badge">{product.discount}</span> */}
        </div>
        <h3 className="product-title">{product.product_name.length>40 ? product.product_name.slice(0,40) + '.....' : product.product_name}</h3>
        {product.offer_price ?
          <div className="product-price">
            <span className="current-price">${product.offer_price}</span>
            <span className="original-price">${product.price}</span>
          </div> :
          <div className="product-price">
            <span className="current-price">${product.price}</span>
          </div>
        }
        <p className="product-description">{product.product_short_disc}</p>

        <div className="card-bottom">
          <button className="add-to-cart-btn" onClick={(e) => {
            // e.preventDefault();
            // e.stopPropagation();
            // handleAddToCart();
          }}>
            ADD TO CART
            <img src={cart} alt="" className="cart-logo" />
          </button>
          <button className="wishlist-btn" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleAddToWishlist();
          }}>
            <img src={whishlist} alt="" className="wishlist-logo" />
          </button>
          <button className="exchange-btn" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}>
            <img src={exchange} alt="" className="exchange-logo" />
          </button>
        </div>

        {visibleSubImages.length > 0 && (
          <div className="sub-images">
            {visibleSubImages.slice(0, 4).map((subImage, index) => (
              //   eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                key={index}
                src={`https://krizaar.com/api/uploads/${subImage}`}
                alt={`Sub image ${index + 1}`}
                className="sub-image"
              />
            ))}
            {/* <button className="next-sub-image-btn" onClick={handleNextSubImage}>
              <img src={arrow} alt="Next" />
            </button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
