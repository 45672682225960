import React from 'react';
import productImage from '../../img/firstlbg.jpg'


const VendorOrders = () => {
    return (
        <>
            <div className="row">
                <div className="vendor-top">
                    <h5 className="title-v">Vendor Order List</h5>
                </div>
            </div>
            <div className='tableGap'>
                <table>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Vendor Name</th>
                            <th>Order Date</th>
                            <th>Payment Status</th>
                            <th>Total Price</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                #1324ABCD
                            </td>
                            <td>Vendor Name</td>
                            <td>
                                25 Aug, 2016
                            </td>
                            <td>Success</td>
                            <td>Rs. 5699</td>
                            <td>Delivered</td>
                            <td>
                                <div className="btn-group border-0">
                                    <button className="edit-btn" >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                #1324ABCD
                            </td>
                            <td>Vendor Name</td>
                            <td>
                                25 Aug, 2016
                            </td>
                            <td>Success</td>
                            <td>Rs. 5699</td>
                            <td>Delivered</td>
                            <td>
                                <div className="btn-group border-0">
                                    <button className="edit-btn" >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                #1324ABCD
                            </td>
                            <td>Vendor Name</td>
                            <td>
                                25 Aug, 2016
                            </td>
                            <td>Success</td>
                            <td>Rs. 5699</td>
                            <td>Delivered</td>
                            <td>
                                <div className="btn-group border-0">
                                    <button className="edit-btn" >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default VendorOrders;