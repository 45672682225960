import React, { useContext, useEffect, useState } from 'react';
import './category.css';
import Card from './ProductCard';
import { FaCaretDown } from 'react-icons/fa';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import { CartContext } from '../cart/CartContext';


const SubCategoryProducts = () => {
  const [selectedFilter, setSelectedFilter] = useState({});
  const [showDropdown, setShowDropdown] = useState('');
  const [categoryInfo, setCategoryInfo] = useState({
    name: '',
    banner: '',
    id: ''
  })
  const [subCategoryInfo, setSubCategoryInfo] = useState({
    name: '',
    banner: '',
  })
  const [products, setProducts] = useState([])

  const { categoryId } = useParams();

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set())

  const handleAddToWishlist = async (productId) => {
    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    if (!accessToken || !userId) {
      alert("You need to be logged in to add products to your wishlist.");
      navigate("/signin");
      return;
    }

    if (wishlist.has(productId)) {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/product/wishlist/delete`,
          {
            userId: userId,
            productIds: [productId],
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.success) {
          alert("Product removed from wishlist successfully!");
          setWishlist((prevWishlist) => {
            const newWishlist = new Set(prevWishlist);
            newWishlist.delete(productId);
            return new Set(newWishlist);
          });
          return;
        } else {
          alert("Failed to remove product from wishlist.");
          return;
        }
      } catch (error) {
        console.error("Error removing product from wishlist:", error);
        alert("An error occurred. Please try again.");
        return;
      }
    }

    try {
      const response = await axios.post(
        `https://krizaar.com/api/product/wishlist/add`,
        {
          userId: userId,
          productId: productId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.success) {
        alert("Product added to wishlist successfully!");
        setWishlist((prevWishlist) => new Set(prevWishlist).add(productId));
      } else {
        alert("Failed to add product to wishlist.");
      }
    } catch (error) {
      console.error("Error adding product to wishlist:", error);
      alert("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchWishlist = async () => {
      const userId = localStorage.getItem("userId");
      const accessToken = Cookies.get('krizaar-user');

      if (!userId || !accessToken) {
        return;
      }

      try {
        const response = await axios.get(
          `https://krizaar.com/api/product/wishlist/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data && response.data.data) {
          const wishlistProductIds = response.data.data.map((item) => item._id);
          setWishlist(new Set(wishlistProductIds));
        }
      } catch (error) {
        console.error("Error fetching wishlist items:", error);
      }
    };
    fetchWishlist();
  }, [])

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.post('https://krizaar.com/api/product/category/product', {
          category_id: categoryId,
          level: 1,
          page: 1,
          pageSize: 10
        })
        setProducts(response.data.data)
        const firstProduct = response.data.data[0]
        // console.log(response.data.data)
        setCategoryInfo({
          name: firstProduct.category_details_level0.category_name,
          banner: firstProduct.category_details_level0.category_image,
          id: firstProduct.category_details_level0._id
        })
        setSubCategoryInfo({
          name: firstProduct.category_details_level1.category_name,
          banner: firstProduct.category_details_level1.category_image
        })
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    fetchProducts();
  }, [categoryId])

  const { state, dispatch } = useContext(CartContext);
  const handleAddToCart = async (product) => {

    const userId = localStorage.getItem("userId");
    const accessToken = Cookies.get('krizaar-user');

    const productInfo = {
      id: product._id,
      image: product.product_banner_image,
      name: product.product_name,
      price: product.price,
      offer_price: product.offer_price,
      seller: product.vendor_details,
      quantity: 1,
    }

    const isItemAlreadyInCart = state.cart.some(item => item.id === product._id);

    if (isItemAlreadyInCart) {
      alert("Product is already in the cart!");
      return;
    }

    if (!accessToken || !userId) {
      dispatch({ type: 'ADD_TO_CART', payload: productInfo });
      alert("Product added to the cart!");
    } else {
      try {
        const response = await axios.post(
          `https://krizaar.com/api/cart/user/add/product/cart`,
          {
            id: userId,
            product_id: product._id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.status === 200) {
          dispatch({ type: 'ADD_TO_CART', payload: productInfo });
          alert("Product added to the cart!");
        } else {
          alert("Failed to add product to the cart");
        }
      } catch (error) {
        console.error("Error adding product to the cart:", error);
        alert("An error occurred. Please try again.");
      }
    }
  }

  return (
    <div className="category-product-page">
      <div className='container'>
        <div className="row my-3">
          <p>
            <b>Catalogue</b> {">"}{" "}
            <b><NavLink className='text-dark' to={`/category/${categoryInfo.id}`}>{categoryInfo.name ? categoryInfo.name : ''}</NavLink></b> {">"}{" "}
            {subCategoryInfo.name ? subCategoryInfo.name : ''}
          </p>
        </div>
        {subCategoryInfo.name && <h3 className='mb-5'>{subCategoryInfo.name} Products</h3>}
        {/* <div className="filters">
          {Object.keys(filters).map((category) => (
            <div key={category} className="filter-dropdown">
              <button className="filter-button" onClick={() => setShowDropdown(showDropdown === category ? '' : category)}>
                {category} <span><FaCaretDown /></span>
              </button>
              {showDropdown === category && (
                <div className="dropdown-options">
                  {filters[category].map((option) => (
                    <div key={option} className="dropdown-option">
                      <input
                        type="checkbox"
                        id={`${category}-${option}`}
                        checked={selectedFilter[category] && selectedFilter[category].includes(option)}
                        onChange={() => handleFilterSelect(category, option)}
                      />
                      <label htmlFor={`${category}-${option}`}>{option}</label>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <button className="reset-button" onClick={() => setSelectedFilter({})}>RESET FILTERS</button>
        </div> */}
        <div className="products-grid row m-0">
          {products.length > 0 ? (
            products.map((product) => (
              <Card key={product._id} product={product} handleAddToCart={handleAddToCart} handleAddToWishlist={handleAddToWishlist} />
            ))
          ) : (
            <p>There are no products in this sub-category</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCategoryProducts;
