import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./ProductCarousel.css";
import ProductCard from "./ProductCard";
// import image1 from '../../assests/illustrations/Image.png';
// import image2 from '../../assests/illustrations/Image (1).png';
// import image3 from '../../assests/illustrations/Image (2).png';
// import image4 from '../../assests/illustrations/Image (3).png';
// import image5 from '../../assests/illustrations/Image (4).png';
// import image6 from '../../assests/illustrations/Image (5).png';
// import image7 from '../../assests/illustrations/Image (6).png';
import product1 from "../img/product1.jpeg";
import product6 from "../img/product5.jpeg";
import axios from "axios";

const ProductCarousel = () => {
  const [backToSchoolProducts, setBackToSchoolProducts] = useState([])
  const [endingSoon, setEndingSoon] = useState([])



  useEffect(() => {
    const fetchBackToSchoolProducts = async () => {
      try {
        const response = await axios.post('https://krizaar.com/api/product/category/product', {
          category_id: '66b46b4a6cafb8f137cc84a3',
          level: 0,
          page: 1,
          pageSize: 10
        })
        setBackToSchoolProducts(response.data.data)
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    const fetchbestQualityProducts = async () => {
      try {
        const response = await axios.post('https://krizaar.com/api/product/getBestQualityProducts', {
          page: 1,
          pageSize: 15
        })
        setEndingSoon(response.data.data)
      } catch (e) {
        console.log("Error in fetching category products: ", e.message)
      }
    };
    fetchbestQualityProducts();
    fetchBackToSchoolProducts();
  }, [])

  const [currentWeeklyDeal, setCurrentWeeklyDeal] = useState(0);
  const [currentEndingSoon, setCurrentEndingSoon] = useState(0);

  const handleWeeklyDealChange = (index) => {
    setCurrentWeeklyDeal(index);
  };

  const handleEndingSoonChange = (index) => {
    setCurrentEndingSoon(index);
  };

  const render = () => {
    const visibleDeals = backToSchoolProducts.slice(
      currentWeeklyDeal,
      currentWeeklyDeal + 2
    );
    return visibleDeals.map((deal) => (
      <ProductCard key={deal.id} product={deal} />
    ));
  };

  const renderEndingSoon = () => {
    const visibleDeals = endingSoon.slice(
      currentEndingSoon,
      currentEndingSoon + 2
    );
    return visibleDeals.map((deal) => (
      <ProductCard key={deal.id} product={deal} />
    ));
  };

  return (
    <section>
      <div className="container my-5">
        <div className="product-carousel">
          <h3 className="tit-heading">Back To School</h3>
          <div className="carousel-content">{render()}</div>
          <div className="carousel-buttons">
            {Array.from({ length: backToSchoolProducts.length }).map((_, index) => (
              <button
                key={index}
                className={`carousel-btn ${currentWeeklyDeal === index ? "active" : ""
                  }`}
                onClick={() => handleWeeklyDealChange(index)}
              ></button>
            ))}
          </div>

          <h3 className="tit-heading">Best Quality</h3>
          <div className="carousel-content">{renderEndingSoon()}</div>
          <div className="carousel-buttons">
            {Array.from({ length: endingSoon.length }).map((_, index) => (
              <button
                key={index}
                className={`carousel-btn ${currentEndingSoon === index ? "active" : ""
                  }`}
                onClick={() => handleEndingSoonChange(index)}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductCarousel;
