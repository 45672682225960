import { KRIZAAR_LIVE, REACT_BASE_PATH } from "./api"

export const _fetchCategoryList = `${REACT_BASE_PATH}/product/getCategoryList`;

export const _saveProductData = `${REACT_BASE_PATH}/product/save/product`
export const _existProductData = `${REACT_BASE_PATH}/product/get/exist/product`

// setting 
export const getSettingDataURL = `${REACT_BASE_PATH}/admin/get/php/setting`
export const updateProductByCategoryURL = `${REACT_BASE_PATH}/admin/get/updateSetting`

export const _feedNameURL = `${KRIZAAR_LIVE}/feedname.php`;
export const _fetchProductData = `${KRIZAAR_LIVE}/get-product-api.php`
export const getAccessTokenURL = `${KRIZAAR_LIVE}/check-outh.php`

// https://krizaar.com/php/sdk/check-outh.php