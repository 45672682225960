import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth(false);

  if (!isAuthenticated) {
    return <Navigate to="/vendorlogin" replace />;
  }

  return children;
};

export default ProtectedRoute;
