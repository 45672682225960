import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../img/krizaalogo.png";
import logo2 from "../img/photo.png";
import logo3 from "../img/exchange.png";
import logo4 from "../img/cart-.png";
import logo5 from "../img/audio.png";
import logo6 from "../img/location.png";
import logo7 from "../img/logout.png";
import profile from "../img/user.png";
import payment from "../img/payment.png";
import cart from "../img/mynaui_cart.png";
import Wishlist from "../img/heart.png";
import rewards from "../img/reward.png";
import notification from "../img/notification.png";
import logout from "../img/logout.png";
import service from "../img/clock.png";
import downloadApp from "../img/application.png";
import userImage from "../img/userImage.png";
import arrowDown from "../img/caret-down.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import { CartContext } from "../../components/WebPanel/cart/CartContext";
import { BorderAll } from "@material-ui/icons";

const Header2 = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({
    name: localStorage.getItem("username"),
    image: userImage,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { pathname } = useLocation();
  const [allCategories, setAllCategories] = useState([]);

  const [location, setLocation] = useState("Fetching location...");

  const CLIENT_GOOGLE_ID = '';

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`Lat: ${latitude.toFixed(2)}, Lon: ${longitude.toFixed(2)}`);

          // try {
          //   const response = await fetch(
          //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${CLIENT_GOOGLE_ID}`
          //   );
          //   const data = await response.json();
          //   console.log(data)
          //   if (data.results && data.results.length > 0) {
          //     setLocation(data.results[0].formatted_address);
          //   } else {
          //     setLocation("Location not found");
          //   }
          // } catch (error) {
          //   console.error("Error fetching location data:", error);
          //   setLocation("Failed to fetch location");
          // }
        },
        (error) => {
          console.error("Error fetching location: ", error);
          setLocation("Location unavailable");
        }
      );
    } else {
      setLocation("Geolocation is not supported by this browser.");
    }
  }, [CLIENT_GOOGLE_ID]);

  // console.log(location)
  useEffect(() => {
    const isUserLoggedIn = async () => {
      const loginUserToken = Cookies.get('krizaar-user');
      const userId = localStorage.getItem('userId');
      if (loginUserToken && userId) {
        try {
          const response = await axios.post(
            `https://krizaar.com/api/auth/user/profile/detail`,
            { id: userId },
            {
              headers: {
                Authorization: `Bearer ${loginUserToken}`,
                "Content-Type": "application/json",
              }
            }
          );
          if (response.data && response.data.userdata) {
            if (response.data.userdata.first_name === null && pathname !== '/userprofile') {
              navigate('/userprofile')
              return;
            }
            setUser({
              name: response.data.userdata.first_name ? response.data.userdata.first_name : ''
                + ' ' +
                response.data.userdata.last_name ? response.data.userdata.last_name : '',
              image: response.data.userdata.image ? response.data.userdata.image : userImage
            })
            setIsLoggedIn(true);
          }
        } catch (e) {
          console.log(e, "Failed to fetch user details due to error: ", e.message)
        }
      }
    }
    const fetchAllCategories = async () => {
      try {
        const response = await axios.get(
          `https://krizaar.com/api/product/category-subcategory`
        );
        if (response.data.success) {
          setAllCategories(response.data.data);
        } else {
          console.error("Error fetching categories:", response.data.message);
          alert("An error occurred while fetching categories. Please try again.");
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
        alert("An error occurred. Please try again.");
      }
    };

    fetchAllCategories();
    isUserLoggedIn();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length > 2) {
      try {
        const response = await fetch(
          "https://krizaar.com/api/product/searchProductsByKeyword",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ keyword: query }),
          }
        );
        const data = await response.json();
        if (data.success) {
          setSearchResults(data.data);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  const toggleDropdownAll = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove('krizaar-user');
    localStorage.removeItem('userId');
    localStorage.removeItem('checkoutState');
    localStorage.removeItem('cartState');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    setUser({ name: null, image: null });
    navigate('/')
  }

  const { state, dispatch } = useContext(CartContext);

  return (
    <header className="header2">
      <div className="d-flex text-align-center logo-section">
        <div className="logo-m">
          <NavLink to="/">
            <img src={logo} alt="Krizaar Logo" className="logo" />
          </NavLink>
        </div>
        <div className="location-section">
          <span className="location">
            <img src={logo6} alt="location" /> Sector-62
          </span>
          <span className="switch-address">Switch Add</span>
        </div>
      </div>

      <div className="search-bar-wrapper">
        <div className="search-bar d-flex align-items-center rounded-pill">
          <li className="nav-item dropdown me-0">
            <button
              className="btn btn-category dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded={dropdownOpen}
              onClick={toggleDropdownAll}
            >
              All
            </button>
            <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
              <div className="dropdown-section">
                <h6 className="ps-3 text-white">Shop by Category</h6>
                <ul>
                  {allCategories.map((category) => (
                    <li className="dropdown-item text-white" key={category._id}>
                      <NavLink to={`/category/${category._id}`}>{category.category_name}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </ul>
          </li>

          <input
            type="text"
            className="form-control border-0"
            placeholder="Search krizaar.com"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ width: '20rem' }}
          />
          <img src={logo5} alt="mic" className="icon" />
          <img src={logo2} alt="camera" className="icon" />
        </div>
        {searchResults.length > 0 && (
          <div className="search-results">
            <ul>
              {searchResults.map((result) => (
                <li key={result._id}>
                  <Link to={`/singleproduct/${result._id}`}>
                    <div className=" d-flex align-items-center gap-3">
                    <div className="image-box" style={{width: '10%'}}>
                      <img src={`https://krizaar.com/api/uploads/${result.product_banner_image}`} alt={result.product_name} className="img-fluid" style={{ width: "100%", height: "2.5rem" }} />
                    </div>
                      {result.product_name.length > 22
                        ? `${result.product_name.slice(0, 22)}...`
                        : result.product_name}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="nav-actions d-flex align-items-center">
        {isLoggedIn ? (
          <div className="user-info" onClick={toggleDropdown}>
            <img src={user.image} alt="User" className="user-image" />
            <span>{user.name}</span>
            <img src={arrowDown} alt="Dropdown Arrow" />
            {showDropdown && (
              <div className="dropdown-menu show">
                <NavLink to="/userprofile" className="dropdown-item">
                  <img src={profile} alt="" className="icon" />
                  My Profile
                </NavLink>
                <NavLink to="/payment" className="dropdown-item">
                  <img src={payment} alt="" className="icon" />
                  Payment
                </NavLink>
                <NavLink to="/allorders" className="dropdown-item">
                  <img src={cart} alt="" className="icon" />
                  Orders
                </NavLink>
                <NavLink to="/whishlist" className="dropdown-item">
                  <img src={Wishlist} alt="" className="icon" />
                  Wishlist
                </NavLink>
                <NavLink to="/faqpage" className="dropdown-item">
                  <img src={rewards} alt="" className="icon" />
                  FAQs
                </NavLink>
                <NavLink to="/notification" className="dropdown-item">
                  <img src={notification} alt="" className="icon" />
                  Notifications
                </NavLink>
                <button onClick={handleLogout} className="dropdown-item">
                  <img src={logout} alt="" className="icon" />
                  Logout
                </button>
                <hr />
                <NavLink to="" className="dropdown-item">
                  <img src={service} alt="" className="icon" />
                  24/7 Customer Service
                </NavLink>
                <NavLink to="" className="dropdown-item">
                  <img src={downloadApp} alt="" className="icon" />
                  Download App
                </NavLink>
              </div>
            )}
          </div>
        ) : (
          <NavLink to="/signin" className="login-link">
            <button
              className="btn btn-outline-primary login-button"
            >
              <img src={logo7} alt="login" className="icon" />
              Log in / Sign up
            </button>
          </NavLink>
        )}
        <NavLink to='/cart' className=' d-flex ' >
          <img src={logo4} alt="cart" className="icon cart-icon1" />
          <div className="cart-quantity-div">{state.totalQuantity}</div>
        </NavLink>
        <img src={logo3} alt="exchange" className="icon exchange-icon" />
        <button className="btn btn-primary seller-button">
          Become an Affiliate
        </button>
      </div>
    </header>
  );
};

export default Header2;
