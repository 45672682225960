import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from "./componenth/common/AuthContext";
import ProtectedRoute from "./componenth/common/ProtectedRoute";
import AdminPanelRoutes from './components/AdminPanelRoutes/AdminPanelRoutes';
import WebPanelRoutes from './components/WebPanelRoutes/WebPanelRoutes';

const App = () => (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/admin/*" element={<ProtectedRoute><AdminPanelRoutes /></ProtectedRoute>} />
        <Route path="/*" element={<WebPanelRoutes />} />
      </Routes>
    </Router>
  </AuthProvider>
);

export default App;

